import React, { useState } from 'react';

//firebase
import firebase from '../../database/firebase'

//router dom
import { Redirect } from 'react-router-dom'

//MaterialUI
import { makeStyles } from "@material-ui/core/styles";
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

//redux
import { useSelector } from 'react-redux';

//utils
import { WhiteButton } from '../../utils/Buttons'
import SearchHeader from './Header/SearchHeader';

const useStyles = makeStyles((theme) =>({
    backgroundContainer: {
        minWidth: '100vw',
        minHeight: '100vh',
        backgroundImage: `url(${process.env.PUBLIC_URL}/img/login/background.jpg)`,
        backgroundSize: 'cover',
        backgroundPosition: 'center'
    },
    overlay: {
        width: '100vw',
        height: '100vh',
        backgroundImage: 'linear-gradient(to bottom, rgba(20,20,20,1)0%, rgba(20,20,20,0.8)50%, rgba(20,20,20,1)100%)',
        textAlign: 'center'
    },
    logoImg: {
        width: '80px',
        position: 'relative',
        top: '20px'
    },
    formContainer: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        padding: '50px',
        width: '90%',
        maxWidth: '500px'
    },
    passwordInput: {
        flex: '1',
        width: '100%',
        padding: '15px',
        outline: 'none',
        borderRadius: '25px',
        border: 'none',
        background: "none",
        color: 'white',
        '&::placeholder': {
            color: 'rgba(255,255,255,0.75)'
        }
    },
    userInput: {
        width: '100%',
        padding: '15px',
        outline: 'none',
        borderRadius: '25px',
        border: 'none',
        marginBottom: '20px',
        background: "rgba(255,255,255,0.25)",
        color: 'white',
        '&::placeholder': {
            color: 'rgba(255,255,255,0.75)'
        }
    },
    passwordContainer: {
        display: 'flex',
        width: '100%',
        background: 'rgba(255,255,255,0.25)',
        borderRadius: '25px',
        border: 'none',
        marginBottom: '20px',
    },
    errorContainer: {
        background: 'rgba(255,255,255,0.25)',
        borderRadius: 10,
        padding: 20,
        marginTop: 20
    },
    errorText: {
        color: 'white',
        fontSize: '25px'
    }
}))

export default function ResetPassword({classes}) {
    classes = useStyles();
    
    //redux
    const user = useSelector(state => state.userState.user);

    //State
    const [values, setValues] = useState({
        currentPassword: '',
        confirmPassword: '',
        password: '',
        currentShowPassword: false,
        showPassword: false,
        confirmShowPassword: false,
        error: "",
        success: ""
    });

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleClickCurrentShowPassword = () => {
        setValues({ ...values, currentShowPassword: !values.currentShowPassword });
    };

    const handleClickConfirmShowPassword = () => {
        setValues({ ...values, confirmShowPassword: !values.confirmShowPassword });
    };

    const handleMouseDownPassword = (e) => {
        e.preventDefault();
    };

    const reauthenticate = (currentPassword) => {
        var currentUser = firebase.firebase.auth().currentUser;
        var cred = firebase.firebase.auth.EmailAuthProvider.credential(currentUser.email, currentPassword);
        return currentUser.reauthenticateWithCredential(cred);
    }

    const changePassword = (currentPassword, newPassword) => {
        reauthenticate(currentPassword).then(() => {
            var currentUser = firebase.firebase.auth().currentUser;
            currentUser.updatePassword(newPassword).then(() => {
                setValues({...values, success: "Se actualizó la contraseña correctamente"})
            }).catch((error) => { console.error(error); });
        }).catch((error) => { console.error(error); });
    }

    const handlePasswordChange = (e) => {
        e.preventDefault();
        if(values.password === values.confirmPassword){
            changePassword(values.currentPassword, values.password)       
        } else {
            setValues({...values, error: "No coinciden las contraseñas"})
        }
    }

    return (
        <div className={classes.backgroundContainer}>
            <div className={classes.overlay}>
                <SearchHeader/>
                <div className={classes.formContainer}>
                    <div className={classes.passwordContainer}>
                        <input 
                            type={values.currentShowPassword ? 'text' : 'password'}
                            placeholder="Contraseña actual"
                            value={values.currentPassword}
                            onChange={(e) => setValues({...values, currentPassword: e.target.value})}
                            className={classes.passwordInput}
                        />
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickCurrentShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            >
                            {values.currentShowPassword ? <Visibility  style={{color: 'white'}}/> : <VisibilityOff style={{color: 'white'}}/>}
                        </IconButton>
                    </div>
                    
                    <div className={classes.passwordContainer}>
                        <input 
                            type={values.showPassword ? 'text' : 'password'}
                            placeholder="Contraseña"
                            value={values.password}
                            onChange={(e) => setValues({...values, password: e.target.value})}
                            className={classes.passwordInput}
                        />
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            >
                            {values.showPassword ? <Visibility  style={{color: 'white'}}/> : <VisibilityOff style={{color: 'white'}}/>}
                        </IconButton>
                    </div>
                    
                    <div className={classes.passwordContainer}>
                        <input 
                            type={values.confirmShowPassword ? 'text' : 'password'}
                            placeholder="Confirmar contraseña"
                            value={values.confirmPassword}
                            onChange={(e) => setValues({...values, confirmPassword: e.target.value})}
                            className={classes.passwordInput}
                        />
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickConfirmShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            >
                            {values.confirmShowPassword ? <Visibility  style={{color: 'white'}}/> : <VisibilityOff style={{color: 'white'}}/>}
                        </IconButton>
                    </div>
                    <div>
                        <WhiteButton onClick={handlePasswordChange}>Cambiar contraseña</WhiteButton>
                    </div>
                    {values.success !== "" && (
                        <div className={classes.errorContainer}>
                            <h2 className={classes.errorText}>{values.success}</h2>
                        </div>
                    )}
                    {values.error !== "" && (
                        <div className={classes.errorContainer}>
                            <h2 className={classes.errorText}>{values.error}</h2>
                        </div>
                    )}
                    {/* loading */}
                    {!user && (
                        <>
                            <Redirect to="/"/>
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}
