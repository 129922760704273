import React, { useEffect } from 'react'

import './App.css';
import Router from "./Router";

//redux

function App() {

  //change for testing pwa alert function


  /* const readData = () => {
    let step = true
    let step2 = true
    firebase.db.collection("news")
    .orderBy("date", "desc")
    .startAt("2019-08-31")
    .endBefore("2019-01-31")
    .onSnapshot((snapshot) => {
      let items = []
      if(step2){
        snapshot.docs.forEach((item) => {
          console.log("reading..")
          step2 = false
          items.push({
            id: item.id,
            title: item.data().title.toLowerCase(),
            date: item.data().date
          })
        }) 
        console.log("---items---", items)
      }
      if(step){
        step = false;
        items.map((item) => {
          var title = item.title.replaceAll("&", "and")
          title = item.title.replaceAll(",", "")
          title = item.title.replaceAll("¿", "")
          title = item.title.replaceAll("?", "")
          title = item.title.replaceAll(";", "")
          title = item.title.replaceAll(".", "")
          var keywords = title.split(" ");
          console.log("date: ", item.date);
          console.log("keywords: ", keywords);
          firebase.db.collection("news").doc(item.id).set({
            keywords: keywords
          },{
            merge: true
          })
        })

        console.log("done") 
      }
      
    })
  } */




  useEffect(() => {
    //localStorage of
    localStorage.setItem("countriesChange", 0)
    //readData()
    /* let items = await readData()
    items.map((item) => {
      var title = item.title.replace("&", "and")
      var keywords = title.split(" ");
      console.log("date: ", item.date);
      console.log("keywords: ", keywords);
      firebase.db.collection("news").doc(item.id).set({
        keywords: keywords
      },{
        merge: true
      })
    }) */

    
  }, [])


  return (
    <>
      <Router/>
    </>
  );
}

export default App;
