import React from 'react'
import News from './News'
import Header from './Header'

//pull to refresh

//redux
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';

export default function Home() {
    const user = useSelector(state => state.userState.user);
    return (
        <>
            {!user && (
                <Redirect to="/"/>
            )}
            {user === "" && (
                <Redirect to="/"/>
            )}
            <Header/>
            <News/>
        </>
    )
}
