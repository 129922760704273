import React from 'react';

//MaterialUI
import { makeStyles  } from "@material-ui/core/styles";
import DescriptionRoundedIcon from '@material-ui/icons/DescriptionRounded';
import ViewHeadlineRoundedIcon from '@material-ui/icons/ViewHeadlineRounded';
import PersonAddRoundedIcon from '@material-ui/icons/PersonAddRounded';
import { IconButton } from '@material-ui/core';
import LockRoundedIcon from '@material-ui/icons/LockRounded';
import DataUsageRoundedIcon from '@material-ui/icons/DataUsageRounded';

//Bootstrap
import { Col, Row } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

//Router dome
import { Link, Redirect } from "react-router-dom";

//redux
import { useSelector, useDispatch } from 'react-redux';
import { logout } from '../../store/actions'

//upload data
import * as d3 from 'd3';
import firebase from '../../database/firebase'
import talacha from './data/talacha.csv'
import usuarios from './data/usuarios.csv'

//CSS
const useStyles = makeStyles((theme) =>({
    backgroundContainer: {
        backgroundImage: 'url(/./img/login/background.jpg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        position: 'relative',
        width: '100vw',
        maxWidth: '100vw',
        minHeight: '100vh',
        textAlign: 'center',
    },
    overlay: {
        width: "100%",
        height: "100%",
        maxWidth: '100vw',
        minHeight: '100vh',
        backgroundImage: 'linear-gradient(to bottom, rgba(20,20,20,1)0%, rgba(20,20,20,0.8)50%, rgba(20,20,20,1)100%)',
        padding: '20px 20px 50px 20px',
        [theme.breakpoints.down('576')]: {
            padding: '20px 10px 50px 10px',
        }
    },
    title: {
        color: "white",
        fontWeight: "bold",
    },
    itemsContainer: {
        width: '100%',
        maxWidth: '1500px',
        margin: 'auto',
        paddingTop: "200px",
        [theme.breakpoints.down('576')]: {
            paddingTop: '50px'
        }
    },
    itemLink: {
      color: 'white',
      '&:hover': {
        color: 'white',
        textDecoration: 'none'
      }
    },
    item: {
      backgroundImage: "url(/./img/admin/phone.jpg)",
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      width: '300px',
      maxWidth: '100%',
      height: '400px',
      margin: 'auto',
      marginBottom: '40px',
      borderRadius: '12px',
      cursor: 'pointer',
      transition: 'transform 450ms',
      '&:hover': {
        transform:'scale(1.06)'
      },
      [theme.breakpoints.down('576')]: {
            height: '200px'
        }
    },
    itemOverlay: {
      width: '100%',
      height: '100%',
      backgroundImage: 'linear-gradient(to bottom, rgba(20,20,20,0.25)0%, rgba(20,20,20,1)100%)',
      borderRadius: '12px'
    },
    itemWrapper: {
        position: 'relative',
        top: '350px',
        width: '100%',
        maxWidth: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingLeft: '10px',
        paddingRight: '10px',
        [theme.breakpoints.down('576')]: {
            top: '150px'
        }
    },
    itemTitle: {
        fontSize: '20px',
        marginBottom: 0,
        [theme.breakpoints.down('576')]: {
            fontSize: '16px',
        }
    },
    itemIcon: {
      position: 'relative',
      right: '10px'
    },
    logoutIcon: {
        color: 'white',
        marginRight: 10,
    },
    logoutLink: {
        color: 'white',
        '&:hover': {
            textDecoration: 'none',
            color: 'white',
        }
    },
    logoutContainer: {
        textAlign: 'left',
        transition: 'transform 450ms',
        width: '200px',
        '&:hover': {
            transform:'scale(1.08)'
        }
    },
    logoutText: {
        color: 'white',
        marginBottom: 0
    }
}))

export default function Index({classes}) {
    classes = useStyles();

    //redux
    const dispatch = useDispatch()
    const user = useSelector(state => state.userState.user);

    const logoutUser = () => {
        dispatch(logout())
    }


    /* var loadFiles = [
        d3.csv(usuarios)
    ]; */

    /* const getCompanies = () => {

        firebase.db.collection("news").where("date", ">=", "2022-07-08").onSnapshot(snapshot => {
            snapshot.docs.forEach(doc => {
                //push to companies if not already in there
                if(!companies.includes(doc.data().company)){
                    companies.push(doc.data().company)
                }
            })

            //sort companies alphabetically
            companies.sort()
            console.log(companies)
            //save in local storage
            localStorage.setItem("companies", JSON.stringify(companies))
        })


    } */

    React.useEffect(() => {
        //getCompanies()


        /* Create users */
         /*Promise.all(loadFiles).then(function (data){
            let users = data[0];
            console.log(users);
            let count = 0;

            users.forEach(user => {
                firebase.db.collection("users").doc(user.uid).set({
                    email: user.email,
                    excludedCountries: [],
                    type: 'user',
                })
                .then(function() {
                    console.log("Document successfully written!");
                })
                .catch(function(error) {
                    console.error("Error writing document: ", error);
                });
                count++;
            });
            console.log(count); 

            users.forEach(user => {
                if(count < 20){
                    console.log(user);
                    firebase.firebase.auth().createUserWithEmailAndPassword(user.email, "telcel")
                    .then((result) => {
                        firebase.db.collection("users").doc(firebase.firebase.auth().currentUser.uid).set({
                            email: user.email,
                            type: "user",
                            excludedCountries: []
                        })
                        console.log("User created");
                    })
                    .catch((error) => {
                        console.log(error);
                    })
                }
                
                count++;
            }) 
            
        }) */
        /* let step = true
        let step2 = true
        firebase.db.collection("news")
        .where("date", "<", "2021-11-01")
        .where("date", ">=", "2021-09-30")
        .onSnapshot((snapshot) => {
        let items = []
        if(step2){
            snapshot.docs.forEach((item) => {
            console.log("reading..")
            step2 = false
            items.push({
                id: item.id,
                title: item.data().title.toLowerCase(),
                date: item.data().date
            })
            }) 
            console.log("---items---", items)
        }
        if(step){
            step = false;
            items.map((item) => {
            var title = item.title.replaceAll("&", "and")
            title = item.title.replaceAll(",", "")
            title = item.title.replaceAll("¿", "")
            title = item.title.replaceAll("?", "")
            title = item.title.replaceAll(";", "")
            title = item.title.replaceAll(".", "")
            var keywords = title.split(" ");
            console.log("date: ", item.date);
            console.log("keywords: ", keywords);
            firebase.db.collection("news").doc(item.id).set({
                keywords: keywords
            },{
                merge: true
            })
            })

            console.log("done") 
        }
        
        }) */

        /* firebase.db.collection("news").where("date", "==", "13/09/21").onSnapshot(snapshot => {
            snapshot.docs.forEach(item => {
                firebase.db.collection("news").doc(item.id).delete()
            })
        }) */

        //upload data from csv
        /* Promise.all(loadFiles).then(function (data){
            let newsData = data[0];
            console.log(newsData);
            let count = 0;
            newsData.map((item) => {
                var title = item.titulo.replaceAll("&", "and")
                title = title.replaceAll(",", "")
                title = title.replaceAll("¿", "")
                title = title.replaceAll("?", "")
                title = title.replaceAll(";", "")
                title = title.replaceAll(".", "")
                var keywords = title.split(" ");
                //console.log(keywords)
                count++;
                firebase.db.collection("news").add({
                    country: item['pais'],
                    title: item['titulo'].replaceAll("}", ","),
                    resumen: item['descripcion'].replaceAll("}", ","),
                    source: item['fuente'],
                    url: item['url'],
                    pdf: item['pdf']  ||  "",
                    sector: item['sector'],
                    date: item['fecha'],
                    newType: 0,
                    destacada: 0
                }).then(console.log("added")).catch(console.log("err"))
                
            })
            console.log("done: ", count)

        }) */
    },[])



    return (
        <div>
            {!user && (
                <Redirect to="/"/>
            )}
            {user === "" && (
                <Redirect to="/"/>
            )}
            {user && (
                <>
                    {user.type !== "admin" && (
                        <Redirect to="/home"/>
                    )}
                </>
            )}
            <div className={classes.backgroundContainer}>
                <div className={classes.overlay}>
                    {/* mini header */}
                    <div className={classes.logoutContainer}>
                        <Link to="/" onClick={logoutUser} className={classes.logoutLink}>
                            <IconButton>
                                <LockRoundedIcon className={classes.logoutIcon}/>
                                <p className={classes.logoutText}>Cerrar sesión</p>
                            </IconButton>
                        </Link>
                    </div>
                    <h1 className={classes.title}>¿Qué quieres hacer chavin?</h1>
                    {/* Opciones */}
                    <div>
                        <Row className={classes.itemsContainer}>
                            <Col xs={6} xl={3}>
                                <Link to="/admin/noticias" className={classes.itemLink}>
                                    <div className={classes.item}>
                                        <div className={classes.itemOverlay}>
                                            <div className={classes.itemWrapper}>
                                                <h3 className={classes.itemTitle}>Subir noticia</h3>
                                                <DescriptionRoundedIcon/>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </Col>
                            <Col xs={6} xl={3}>
                                <Link to="/admin/newsletter" className={classes.itemLink}>
                                    <div className={classes.item} style={{backgroundImage: 'url(/./img/admin/news.jpg)'}}>
                                        <div className={classes.itemOverlay}>
                                            <div className={classes.itemWrapper}>
                                                <h3 className={classes.itemTitle}>Generar Newsletter</h3>
                                                <ViewHeadlineRoundedIcon/>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </Col>
                            <Col xs={6} xl={3}>
                                <Link to="/admin/resumen" className={classes.itemLink}>
                                    <div className={classes.item} style={{backgroundImage: 'url(/./img/admin/planet.jpg)'}}>
                                        <div className={classes.itemOverlay}>
                                            <div className={classes.itemWrapper}>
                                                <h3 className={classes.itemTitle}>Resumen noticias</h3>
                                                <DataUsageRoundedIcon/>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </Col>
                            <Col xs={6} xl={3}>
                                <Link to="/admin/edit-users" className={classes.itemLink}>
                                    <div className={classes.item} style={{backgroundImage: 'url(/./img/admin/user.jpg)'}}>
                                        <div className={classes.itemOverlay}>
                                            <div className={classes.itemWrapper}>
                                                <h3 className={classes.itemTitle}>Editar usuarios</h3>
                                                <PersonAddRoundedIcon/>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </div>
    )
}

