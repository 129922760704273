import React, { useState, useEffect } from 'react'

//firebase
import firebase from '../../database/firebase'

//Components
import Header from './Header'
import { TransparentInput } from '../../utils/Select'
import { WhiteRadio } from '../../utils/RadioButtons';
import { BlackButton, WhiteButton } from '../../utils/Buttons'

//MaterialUI
import { makeStyles } from "@material-ui/core/styles";
import { IconButton, Tooltip } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Collapse from '@material-ui/core/Collapse';
import ArrowDropDownRoundedIcon from '@material-ui/icons/ArrowDropDownRounded';
import ArrowDropUpRoundedIcon from '@material-ui/icons/ArrowDropUpRounded';
import LanguageRoundedIcon from '@material-ui/icons/LanguageRounded';
import PictureAsPdfRoundedIcon from '@material-ui/icons/PictureAsPdfRounded';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';

//redux
import { useSelector } from 'react-redux';

//Router dome
import { Redirect } from "react-router-dom";

//Constants
import countries from '../../constants/countries'

//loader
import Loader from "react-loader-spinner";


const useStyles = makeStyles((theme) =>({
    backgroundContainer: {
        backgroundImage: 'url(/./img/login/background.jpg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundAttachment: 'fixed',
        overflow: "hidden",
        [theme.breakpoints.up('1200')]: {
            backgroundAttachment: 'fixed'
        },
        minHeight: '100vh',
    },
    overlay: {
        backgroundImage: 'linear-gradient(to bottom, rgba(20,20,20,1)0%, rgba(20,20,20,0.9)50%, rgba(20,20,20,1)100%)',
        paddingBottom: "50px",
        minHeight: '100vh',
    },
    title: {
        color: "white",
        textAlign: "center",
        fontWeight: "bold",
        marginBottom: "50px",
        [theme.breakpoints.down('576')]: {
            fontSize: 30
        }
    },
    formContainer: {
        width: "95%",
        maxWidth: "600px",
        margin: "auto",
        //background: "rgba(255, 255, 255,0.15)",
        //padding: "20px",
        borderRadius: "10px",
        paddingTop: "35px",
        marginBottom: "30px"
    },
    selectsContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "20px"
    },
    errorContainer2: {
        background: 'none',
        color: 'white !important',
        width: "90%",
        margin: 'auto',
        maxWidth: 600,
        position: 'relative',
        top: '20vh',
    },
    loaderContainer:  {
        margin: 'auto',
        width: 80,
        position: 'relative',
        top: '-35px'
    },

    terContainer: {
        background: 'none',
        color: 'white !important',
        width: "90%",
        margin: 'auto',
        maxWidth: 900,
        marginBottom: '50px',
    },
    terTitle: {
        fontSize: "30px",
        fontWeight: "bold",
        marginBottom: "20px",
        textAlign: "center",
        textDecoration: "underline"
    },
    newsInnerContainer: {
        fontSize: "20px",
        fontWeight: "bold",
        marginBottom: "10px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    countryContainer: {
        display: "flex",
        alignItems: "center",
        justifyItems: "center",
        paddingTop: "20px",
        paddingBottom: "10px",
    },
    countryTitle: {
        fontSize: "20px",
        fontWeight: "bold",
        marginBottom: "0px",
    },
    flag: {
        width: "40px",
        height: "40px",
        borderRadius: "50px",
        backgroundSize: "auto",
        backgroundPosition: "center",
        marginRight: "20px",
        overflow: "hidden"
    },
    newsItemTitle: {
        fontSize: "20px",
        fontWeight: "bold",
        marginBottom: "10px",
        textDecoration: "underline"
    },
    icon: {
        color: "white",
        fontSize: "30px",
    },
    newsResumen: {
        fontSize: "14px",
        flex: 1,
    },

    /* menu */
    menuContainer: {
        position: 'fixed',
        top: '30%',
        right: '20px',
        background: 'none',
        color: 'white'
    },
    menuItem: {
        fontSize: "20px",
        fontWeight: "bold",
        marginBottom: "10px",
        textDecoration: "underline",
        color: 'white'
    },
}))

//initial news state
const initialNewsState = [
                {
                    title: "México",
                    id: "mexico",
                    ter: 1,
                    news: []
                },
                {
                    title: "Caribe",
                    id: "caribe",
                    ter: 2,
                    news: []
                },
                {
                    title: "Cenam",
                    id: "cenam",
                    ter: 3,
                    news: []
                },
                {
                    title: "Region Andina",
                    id: "regionAndina",
                    ter: 4,
                    news: []
                },
                {
                    title: "Europa - tag",
                    id: "europa",
                    ter: 5,
                    news: []
                },
                {
                    title: "Brazil",
                    id: "brazil",
                    ter: 6,
                    news: []
                },
                {
                    title: "Cono Sur",
                    id: "conoSur",
                    ter: 7,
                    news: []
                },
                {
                    title: "Otros",
                    id: "otros",
                    ter: 8,
                    news: []
                }
            ]


export default function NoticiasTemp({classes}) {
    //useState
    const [state, setState] = useState({
        year: 2022,
        years: [],
        month: 1,
        loading: false,
        menuItemsLength: 0,
        news: localStorage.getItem('resumenNews') ? JSON.parse(localStorage.getItem('resumenNews')) : [
                {
                    title: "México",
                    id: "mexico",
                    ter: 1,
                    news: []
                },
                {
                    title: "Caribe",
                    id: "caribe",
                    ter: 2,
                    news: []
                },
                {
                    title: "Cenam",
                    id: "cenam",
                    ter: 3,
                    news: []
                },
                {
                    title: "Region Andina",
                    id: "regionAndina",
                    ter: 4,
                    news: []
                },
                {
                    title: "Europa - tag",
                    id: "europa",
                    ter: 5,
                    news: []
                },
                {
                    title: "Brazil",
                    id: "brazil",
                    ter: 6,
                    news: []
                },
                {
                    title: "Cono Sur",
                    id: "conoSur",
                    ter: 7,
                    news: []
                },
                {
                    title: "Otros",
                    id: "otros",
                    ter: 8,
                    news: []
                }
            ],
        menuItems: [
            "mexico", 
            "caribe",
            "cenam",
            "regionAndina",
            "europa",
            "brazil",
            "conoSur",
            "otros"
        ]
    })

    //useState - copy to clipboard
    const [copied, setCopied] = useState(false);

    
    classes = useStyles(); //styles

    //redux
    const user = useSelector(state => state.userState.user);


    /* Functions */
    const setUp = () => {
        var today = new Date();
        var yyyy = today.getFullYear(); //year
        var mm = today.getMonth()+1; //month

        /* if(mm === 1){
            yyyy = yyyy - 1;
            mm = 12;
        } else {
            mm = mm - 1;
        } */

        var years = [];
        for(var i = yyyy; i >= yyyy-5; i--) years.push(i);
        
        setState({
            ...state,
            year: yyyy,
            years: years,
            month: mm
        })   
    }

    const getTer = (country) => {
        let ter = 0;
        countries.forEach(c => {
            if(c.name === country) ter = c.ter;
        })
        return ter;
    }


    async function waitt() {
        setState({
            ...state,
            loading: true,
            news: initialNewsState
        })
        localStorage.setItem('resumenNews', JSON.stringify(initialNewsState));
        await new Promise(resolve => setTimeout(resolve, 1000));
    }


    const fetchNews = () => {
        let items = [];
        waitt();

        var initialDate = state.year + "-"+ (state.month < 10 ? "0"+ state.month: state.month) + "-01";
        var finalDate = state.year+"-" + (state.month < 10 ? "0"+ state.month: state.month) + "-31";
        firebase.db.collection("news")
            .where("date", ">=", initialDate)
            .where("date", "<=", finalDate)
            .onSnapshot(snapshot => {
                snapshot.docs.forEach((item) => {
                    items.push({
                        id: item.id,
                        country: item.data().country,
                        title: item.data().title,
                        url: item.data().url,
                        pdf: item.data().pdf,
                        resumen: item.data().resumen,
                        source: item.data().source,
                        ter: getTer(item.data().country),
                        isFirst: false,
                        status: false
                    })
                })
        
                //sort items by ter and country
                items.sort((a,b) => {
                    if(a.ter > b.ter) return 1;
                    if(a.ter < b.ter) return -1;
                    if(a.country > b.country) return 1;
                    if(a.country < b.country) return -1;
                    return 0;
                })

                //check if new is first of country
                items.forEach((item, index) => {
                    if(index === 0) item.isFirst = true;
                    else if(item.ter !== items[index-1].ter || item.country !== items[index-1].country) item.isFirst = true;
                })

                //set news
                var tempNews = [
                    {
                        title: "México",
                        id: "mexico",
                        ter: 1,
                        news: []
                    },
                    {
                        title: "Caribe",
                        id: "caribe",
                        ter: 2,
                        news: []
                    },
                    {
                        title: "Cenam",
                        id: "cenam",
                        ter: 3,
                        news: []
                    },
                    {
                        title: "Region Andina",
                        id: "regionAndina",
                        ter: 4,
                        news: []
                    },
                    {
                        title: "Europa - tag",
                        id: "europa",
                        ter: 5,
                        news: []
                    },
                    {
                        title: "Brazil",
                        id: "brazil",
                        ter: 6,
                        news: []
                    },
                    {
                        title: "Cono Sur",
                        id: "conoSur",
                        ter: 7,
                        news: []
                    },
                    {
                        title: "Otros",
                        id: "otros",
                        ter: 8,
                        news: []
                    }
                ];

                //push items to tempNews
                items.forEach(item => {
                    tempNews[item.ter-1].news.push(item);
                })

                //set menuItems
                var menuItems = [];
                tempNews.forEach(item => {
                    if(item.news.length > 0) menuItems.push(item.id);
                })
                localStorage.setItem("menuItems", JSON.stringify(menuItems));

                //setState and localStorage
                localStorage.setItem('resumenNews', JSON.stringify(tempNews));
                setState({
                    ...state,
                    loading: false,
                    news: tempNews,
                    menuItems: menuItems
                })
            })
        
    }

    useEffect(() => {
        setUp();
    },[])

    const openInNewTab = (url) => {
        const newWindow = window.open(url, "_blank", "noopener,noreferrer");
        if (newWindow) newWindow.opener = null;
    };
  
    const setNewsStatus = (id, status) => {
        var tempNews = [...state.news];
        tempNews.forEach(ter => {
            ter.news.forEach(item => {
                if(item.id === id) item.status = status;
            })
        })
        
        setState({
            ...state,
            news: tempNews
        })
        localStorage.setItem('resumenNews', JSON.stringify(tempNews));
    }

    const getCountryImage = (country) => {
        var image = "";
        countries.forEach(c => {
            if(c.name === country) image = c.imgUrl;
        })
        return image;
    }

     //copy to clipboard
    const handleCopyToClipboard = (text) => {
        navigator.clipboard.writeText(text)
        .then(() => {
            console.log('Code copied to clipboard');
            setCopied(true);
            setTimeout(() => {
                setCopied(false);
            }, 1000);
            
        })
        .catch((error) => {
            console.error('Failed to copy code to clipboard:', error);
            // You can show an error message or perform any other error handling here
        });
    };

    return (
        <div className={classes.backgroundContainer}>
            <div className={classes.overlay}>
                {!user && (
                    <Redirect to="/"/>
                )}
                <Header/>

                {/* Fixed menu */}
                {state.news.length !== 0 && (
                    <div className={classes.menuContainer}>
                        {state.news.map((item, index) => (
                            <div key={index}>
                                {item.news.length !== 0 && (
                                    <div  className={classes.menuItemContainer}>
                                        <a className={classes.menuItem} key={index} href={`#${item.id}`}>{item.title}</a>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                )}


                {/* month and year selection */}
                <div className={classes.formContainer}>
                    <div className={classes.selectsContainer}>
                        <Select
                            value={state.month}
                            onChange={(e) => setState({...state, month: e.target.value})}
                            input={<TransparentInput />}
                            label="Mes"
                        >
                            <MenuItem value={1}>Enero</MenuItem>
                            <MenuItem value={2}>Febrero</MenuItem>
                            <MenuItem value={3}>Marzo</MenuItem>
                            <MenuItem value={4}>Abril</MenuItem>
                            <MenuItem value={5}>Mayo</MenuItem>
                            <MenuItem value={6}>Junio</MenuItem>
                            <MenuItem value={7}>Julio</MenuItem>
                            <MenuItem value={8}>Agosto</MenuItem>
                            <MenuItem value={9}>Septiembre</MenuItem>
                            <MenuItem value={10}>Octubre</MenuItem>
                            <MenuItem value={11}>Noviembre</MenuItem>
                            <MenuItem value={12}>Diciembre</MenuItem>
                        </Select>
                        <Select
                            value={state.year}
                            onChange={(e) => setState({...state, year: e.target.value})}
                            input={<TransparentInput />}
                            label="Año"
                        >
                            {state.years.map((year, index) => (
                                <MenuItem key={index} value={year}>{year}</MenuItem>
                            ))}
                        </Select>
                    </div>
                    <WhiteButton onClick={() => fetchNews()}>Buscar</WhiteButton>
                </div>

                {/* news */}
                {state.news.length !== 0 ? (
                    <div className={classes.newsContainer}>
                        {state.news.map((item, index) => (
                            <>
                                {item.news.length !== 0 && (
                                    <div key={index} className={classes.terContainer} id={`${item.id}`}>
                                        <div className={classes.terTitle}>{item.title}</div>
                                        <div className={classes.news}>
                                            {item.news.map((newsItem, indexx) => (
                                                <div key={indexx} className={classes.newsItem}>
                                                    {newsItem.isFirst && (
                                                        <div className={classes.countryContainer}>
                                                            <div className={classes.flag} style={{backgroundImage: `url(${process.env.PUBLIC_URL}${getCountryImage(newsItem.country)})`, backgroundSize: newsItem.country === "Dominicana" ? "cover" : "auto"}}></div>
                                                            <p className={classes.countryTitle}>{newsItem.country}</p>
                                                        </div>
                                                    )}
                                                    <div className={classes.newsInnerContainer}>
                                                        <div className={classes.newsTitle}>{newsItem.title}</div>
                                                        <div style={{display: 'flex'}}>
                                                            <Tooltip title={copied ? "Copiado" : "Copiar"} placement="top">
                                                                <IconButton onClick={() => {handleCopyToClipboard(newsItem.title)}}>
                                                                {copied ? <CheckCircleRoundedIcon className="text-white" /> : <FileCopyIcon className="text-white" />}
                                                                </IconButton>
                                                            </Tooltip>
                                                            {newsItem.status ? (
                                                                <IconButton onClick={() => setNewsStatus(newsItem.id, false)}>
                                                                    <ArrowDropUpRoundedIcon className={classes.icon}/>
                                                                </IconButton>
                                                            ) : (
                                                                <IconButton onClick={() => setNewsStatus(newsItem.id, true)}>
                                                                    <ArrowDropDownRoundedIcon className={classes.icon}/>
                                                                </IconButton>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <Collapse in={newsItem.status} style={{width: '100%'}}>
                                                        <div className={classes.newsInnerContainer}>
                                                            <h3 className={classes.newsResumen}>{newsItem.resumen ? newsItem.resumen : ""}</h3>
                                                            {newsItem.pdf && (
                                                                <IconButton onClick={() => {openInNewTab(newsItem.pdf)}}>
                                                                    <PictureAsPdfRoundedIcon className={classes.icon}/>
                                                                </IconButton>
                                                            )}
                                                            {newsItem.url && (
                                                                <IconButton onClick={() => {openInNewTab(newsItem.url)}}>
                                                                    <LanguageRoundedIcon className={classes.icon}/>
                                                                </IconButton>
                                                            )}
                                                        </div>
                                                    </Collapse>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </>
                        ))}
                    </div>
                ) : (
                    <div className={classes.errorContainer2}>
                        {state.loading ? (
                            /* loading */
                            <div className={classes.loaderContainer}>
                                <Loader  type="BallTriangle" color="#FFFFFF" height={80} width={80} />
                            </div>
                        ) : (
                            /* No results */
                            <div>
                                <h2 className={classes.errorMessage}>Lo sentimos... no hay resultados para su búsqueda</h2>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    )
}
