import React, { useState } from 'react';

//firebase
import firebase from "../../database/firebase";

//router dom
import { Redirect } from 'react-router-dom'

//redux
import { useSelector } from 'react-redux';

//Components
import Header from './Header'
import EditUsers from './EditUsers'

//MaterialUI
import { makeStyles } from "@material-ui/core/styles";
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

//utils
import { WhiteButton } from '../../utils/Buttons'

const useStyles = makeStyles((theme) =>({
    backgroundContainer: {
        minWidth: '100vw',
        minHeight: '100vh',
        backgroundImage: 'url(/./img/login/background.jpg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        [theme.breakpoints.up('1200')]: {
            backgroundAttachment: 'fixed'
        }
    },
    overlay: {
        width: '100vw',
        minHeight: '100vh',
        backgroundImage: 'linear-gradient(to bottom, rgba(20,20,20,1)0%, rgba(20,20,20,0.9)50%, rgba(20,20,20,1)100%)',
        paddingBottom: 50
    },
    title: {
        color: "white",
        textAlign: "center",
        fontWeight: "bold",
        marginBottom: '50px',
        [theme.breakpoints.down('576')]: {
            fontSize: 30,
            marginBottom: '150px',
        }
    },
    formContainer: {
        padding: '50px',
        width: '90%',
        maxWidth: '600px',
        background: "rgba(255, 255, 255,0.05)",
        borderRadius: "20px",
        margin: 'auto',
        marginBottom: 50
    },
    passwordInput: {
        flex: '1',
        width: '100%',
        padding: '15px',
        outline: 'none',
        borderRadius: '25px',
        border: 'none',
        background: 'none',
        color: 'white',
        '&::placeholder': {
            color: 'rgba(255,255,255,0.75)'
        }
    },
    userInput: {
        width: '100%',
        padding: '15px',
        outline: 'none',
        borderRadius: '25px',
        border: 'none',
        marginBottom: '20px',
        background: 'rgba(255,255,255,0.25)',
        color: 'white',
        '&::placeholder': {
            color: 'rgba(255,255,255,0.75)'
        }
    },
    passwordContainer: {
        display: 'flex',
        width: '100%',
        borderRadius: '25px',
        border: 'none',
        marginBottom: '20px',
        background: 'rgba(255,255,255,0.25)'
    },
    errorContainer: {
        background: 'rgba(255,255,255,0.25)',
        borderRadius: 10,
        padding: 20,
        marginTop: 20
    },
    errorText: {
        color: 'white',
        fontSize: '25px'
    },
}))

export default function Register({classes}) {
    classes = useStyles();
    const [values, setValues] = useState({
        email: '',
        password: '',
        showPassword: false,
        type: "admin",
        succes: "", //succes message
        error: ""
    });

    //redux
    const user = useSelector(state => state.userState.user);

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleMouseDownPassword = (e) => {
        e.preventDefault();
    };

    const handleRegister = (e) => {
        e.preventDefault();
        setValues({...values, loading: !values.loading})
        
        const { email, password } = values;
        firebase.firebase.auth().createUserWithEmailAndPassword(email, password)
        .then((result) => {
            firebase.db.collection("users").doc(firebase.firebase.auth().currentUser.uid).set({
                email: email,
                type: "user",
                excludedCountries: []
            })
            setValues({...values, succes: "Se agregó exitosamente", error: ""})
        })
        .catch((error) => {
            setValues({...values, succes: "", error: error.message})
        })

    }

    return (
        <div className={classes.backgroundContainer}>
            <div className={classes.overlay}>
                {!user && (
                    <Redirect to="/"/>
                )}
                <Header/>
                <h1 className={classes.title}>Crea un usuario</h1>
                <div className={classes.formContainer}>
                    <div style={{display: 'flex'}}>
                        <input 
                            type="text" 
                            placeholder="Usuario"
                            value={values.email}
                            onChange={(e) => setValues({...values, email: e.target.value})}
                            className={classes.userInput}
                        />
                    </div>
                    
                    <div className={classes.passwordContainer}>
                        <input 
                            type={values.showPassword ? 'text' : 'password'}
                            placeholder="Contraseña"
                            value={values.password}
                            onChange={(e) => setValues({...values, password: e.target.value})}
                            className={classes.passwordInput}
                        />
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            >
                            {values.showPassword ? <Visibility style={{color: 'white'}}/> : <VisibilityOff style={{color: 'white'}}/>}
                        </IconButton>
                    </div>
                    <div>
                        <WhiteButton onClick={handleRegister}>Registar</WhiteButton>
                        {values.error !== "" && (
                            <div className={classes.errorContainer}>
                                <h2 className={classes.errorText}>{values.error}</h2>
                            </div>
                        )}
                        {values.succes !== "" && (
                            <div className={classes.errorContainer}>
                                <h2 className={classes.errorText}>{values.succes}</h2>
                            </div>
                        )}
                    </div>
                </div>
                <h1 className={classes.title}>Lista de usuarios</h1>
                <EditUsers/>
            </div>
        </div>
    )
}
