import React, { useState } from 'react'

//MaterialUI
import { makeStyles  } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import VpnKeyRoundedIcon from '@material-ui/icons/VpnKeyRounded';
import HomeRoundedIcon from '@material-ui/icons/HomeRounded';
import PersonRoundedIcon from '@material-ui/icons/PersonRounded';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import LockRoundedIcon from '@material-ui/icons/LockRounded';

//Link
import { Link } from "react-router-dom";

//Components

//Menu drawer
import clsx from "clsx";
import "./hamburgers/dist/hamburgers.css";
import { IconButton } from '@material-ui/core';

//Redux
import { useDispatch } from "react-redux";
import { logout } from '../../../store/actions'


const drawerWidth = 240;

const useStyles = makeStyles((theme) =>({
    headerContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '10px',
        backgroundImage: 'linear-gradient(to bottom, rgba(20,20,20,1)0%, rgba(20,20,20,1)100%)',
        position: "relative",
        top: 0,
        width: "100vw",
        height: "70px",
        zIndex: 99,
    },
    logoContainer: {
        flex: '1',
        backgroundColor: "none",
        textAlign: "center"
    },
    logoImg: {
        width: '80px',
        margin: "auto",
        height: '48px',
        position: 'relative',
        left: '0px'
    },
    hamburguerMenu: {
        backgroundColor: `${"white"} !important`,
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        backgroundColor: "rgba(20,20,20,1)",
        zIndex: "9999",
        border: 'none',
        height: 'auto',
        top: "60px",
        borderBottomRightRadius: '10px',
    },
    navButton: {
        outline: "none !important",
        zIndex: "10",
    },
    movilMenuContainer: {
        paddingTop: '40px',
        paddingBottom: '10px',
        paddingLeft: 30
    },
    menuIcon: {
        color: 'white',
        transition: 'transform 450ms',
        fontSize: '30px',
        '&:hover': {
            transform: 'scale(1.2)'
        }
    },
    menuItemContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        transition: 'transform 450ms',
        '&:hover': {
            transform: 'scale(1.08)'
        }
    },
    menuItemText: {
        textAlign: 'left',
        flex: 1,
        marginBottom: 0,
        color: 'white'
    },
    menuItemLink: {
        color: 'white',
        '&:hover': {
            textDecoration: 'none',
            color: 'white'
        }
    },
    menuItemIcon: {
        color: 'white'
    }
}))



export default function Index({classes}) {
    classes = useStyles();
    const [open, setOpen] = useState(false);
    
    //redux
    const dispatch = useDispatch();

    const logoutUser = () => {
        dispatch(logout())
    }


    return (
        <div id={"header"}>
                <div className={classes.headerContainer}>
                    <div>
                        <IconButton
                            className={clsx(
                                "hamburger hamburger--spin",
                                {
                                ["is-active"]: open,
                                },
                                classes.navButton
                            )}
                            type="button"
                            onClick={() => setOpen(!open)}
                            >
                            <span className="hamburger-box">
                                <span
                                className={clsx("hamburger-inner", classes.hamburguerMenu)}
                                ></span>
                            </span>
                        </IconButton>
                    </div>
                    <div className={classes.logoContainer}>
                        <Link to="/inicio">
                            <img alt="Logo" className={classes.logoImg} src={process.env.PUBLIC_URL + "/img/logo/logo.png"}/>
                        </Link>
                    </div>
                    <div>
                        <Link to="/buscar">
                            <IconButton>
                                <SearchRoundedIcon className={classes.menuIcon}/>
                            </IconButton>
                        </Link>
                    </div>
                </div>
                <Drawer
                    className={classes.drawer}
                    variant="persistent"
                    anchor="left"
                    open={open}
                    classes={{
                        paper: classes.drawer,
                    }}
                >
                    <div className={classes.movilMenuContainer}>
                        <div className={classes.menuItem}>
                            <Link to="/inicio" className={classes.menuItemLink}>
                                <div className={classes.menuItemContainer}>
                                    <IconButton>
                                        <HomeRoundedIcon className={classes.menuItemIcon}/>
                                    </IconButton>
                                    <p className={classes.menuItemText}>Inicio</p>
                                </div>
                            </Link>
                        </div>
                        <div className={classes.menuItem}>
                            <Link to="/preferencias" className={classes.menuItemLink}>
                                <div className={classes.menuItemContainer}>
                                    <IconButton>
                                        <PersonRoundedIcon className={classes.menuItemIcon}/>
                                    </IconButton>
                                    <p className={classes.menuItemText}>Preferencias</p>
                                </div>
                            </Link>
                        </div>
                        <div className={classes.menuItem}>
                            <Link to="/gerencia" className={classes.menuItemLink}>
                                <div className={classes.menuItemContainer}>
                                    <IconButton>
                                        <InfoRoundedIcon className={classes.menuItemIcon}/>
                                    </IconButton>
                                    <p className={classes.menuItemText}>Gerencia BI</p>
                                </div>
                            </Link>
                        </div>
                        <div className={classes.menuItem}>
                            <Link to="/reset-password" className={classes.menuItemLink}>
                                <div className={classes.menuItemContainer}>
                                    <IconButton>
                                        <VpnKeyRoundedIcon className={classes.menuItemIcon}/>
                                    </IconButton>
                                    <p className={classes.menuItemText}>Cambiar contraseña</p>
                                </div>
                            </Link>
                        </div>
                        <div className={classes.menuItem}>
                            <div className={classes.menuItemLink} onClick={logoutUser}>
                                <div className={classes.menuItemContainer}>
                                    <IconButton>
                                        <LockRoundedIcon className={classes.menuItemIcon}/>
                                    </IconButton>
                                    <p className={classes.menuItemText}>Cerrar Sesión</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Drawer>            
        </div>
    )
}