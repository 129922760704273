import React, { useEffect, useState } from 'react'
import firebase from '../../database/firebase'

//Link
import { Redirect, useLocation } from "react-router-dom";

//social share
import {
    EmailShareButton,
    FacebookShareButton,
    WhatsappShareButton,
    TwitterShareButton,
    FacebookIcon,
    TwitterIcon,
    WhatsappIcon,
    EmailIcon,
} from "react-share";

//MaterialUI
import { makeStyles  } from "@material-ui/core/styles";
import { IconButton } from '@material-ui/core';
import { CloseRounded, FileCopyRounded } from '@material-ui/icons';
import ShareRoundedIcon from '@material-ui/icons/ShareRounded';
import LanguageRoundedIcon from '@material-ui/icons/LanguageRounded';
import PictureAsPdfRoundedIcon from '@material-ui/icons/PictureAsPdfRounded';
import Backdrop from '@material-ui/core/Backdrop';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

//Components
import NewHeader from './Header/NewHeader';

//redux
import { useSelector } from 'react-redux';

//Constants
import countries from '../../constants/countries'

const useStyles = makeStyles((theme) =>({
    root: {
        width: '100%',
        '& > * + *': {
        marginTop: theme.spacing(2),
        },
    },
    firstNewContainer: {
        paddingTop: 0,
        width: "100vw",
        minHeight: "100vh",
        backgroundImage: `url(${process.env.PUBLIC_URL}/img/news/img${Math.floor((Math.random() * 169) + 1)}.jpg)`,
        backgroundSize: "cover",
        backgroundPosition: "center"
    },
    firstNewOverlay: {
        width: "100%",
        minHeight: "100vh",
        backgroundImage: 'linear-gradient(to bottom, rgba(20,20,20,1)10%, rgba(20,20,20,0.50)50%, rgba(20,20,20,1)75%)',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    firstNewInnerContainer: {
        background: 'none',
        color: 'white !important',
        width: "95%",
        margin: 'auto',
        maxWidth: 900
    },
    firstNewCountryContainer: {
        display: "flex",
        alignItems: "center",
        justifyItems: "center"
    },
    flag: {
        width: "40px",
        height: "40px",
        borderRadius: "50px",
        backgroundSize: "auto",
        backgroundPosition: "center",
        marginRight: "20px",
        overflow: "hidden"
    },
    firstNewCountry: {
        marginBottom: 0
    },
    firstNewTitle: {
        fontSize: "45px",
        fontWeight: 'bold',
        marginTop: 30,
        marginBottom: 30,
        [theme.breakpoints.down('576')]: {
            fontSize: 25
        }
    },
    firstNewInfoContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    icons: {
        color: 'white',
        transition: 'transform 450ms',
        fontSize: '30px',
        '&:hover': {
            transform: 'scale(1.2)'
        }
    },
    firstNewSource: {
        marginBottom: 5,
        fontWeight: "bold",
        fontSize: "20px"
    },
    firstNewDate: {
        marginBottom: 0,
        fontStyle: 'italic'
    },
    newsOuterContainer: {
        background: 'rgb(20,20,20)',
    },
    newCol: {
        [theme.breakpoints.down('576')]: {
            padding: 0
        }
    },
    newContainer: {
        width: '90%',
        maxWidth: '300px',
        margin: 'auto',
        minHeight: '400px',
        backgroundImage: "url(./img/news/img5.jpg)",
        backgroundSize: "cover",
        backgroundPosition: "center",
        borderRadius: '10px',
        marginBottom: 30,
        [theme.breakpoints.down('576')]: {
            minHeight: '200px',
            width: '100%',
            maxWidth: '400px',
            marginBottom: 0,
            borderRadius: '0px',
            borderBottom: '1px solid lightgray'
        },
        transition:  'transform 450ms',
        '&:hover': {
            transform: 'scale(1.02)'
        }
    },
    newOverlay: {
        width: "100%",
        height: "100%",
        minHeight: '400px',
        background: 'rgba(20,20,20,0.65)',
        padding: '10px',
        [theme.breakpoints.down('576')]: {
            minHeight: '200px'
        }
    },
    newInnerContainer: {
        minHeight: '380px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        [theme.breakpoints.down('576')]: {
            minHeight: '200px'
        }
    },
    newsContainer: {
        position: 'relative',
        top: '-200px',
        color: 'white !important',
        width: "95%",
        margin: 'auto',
        maxWidth: 900,
        [theme.breakpoints.down('576')]: {
            top: '-200px',
            width: '100%'
        }
    },
    newFlag: {
        width: "30px",
        height: "30px",
        borderRadius: "50px",
        backgroundSize: "auto",
        backgroundPosition: "center",
        marginRight: "20px",
        overflow: "hidden"
    },
    newTitle: {
        fontSize: '20px',
        fontWeight: 'bold',
    },
    newLink: {
        color: 'white !important',
        '&:hover': {
            textDecoration: 'none',
        }
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    shareContainer: {
        background: 'rgb(20,20,20)',
        padding: 20,
        borderRadius: 10,
        width: '90%',
        maxWidth: '800px'
    },
    shareHeaderContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    shareCloseIcon: {
        color: 'white',
        fontSize: 35,
        transition: 'transform 450ms',
        '&:hover': {
            transform: 'scale(1.2)'
        }
    },
    shareIconContainer: {
        display: 'flex',
        justifyContent: 'space-evenly',
        margin: 'auto',
        maxWidth: 400,
        width: '95%'
    }
}))

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function News({classes, newItem}) {
    classes = useStyles()

    const user = useSelector(state => state.userState.user);

    const [state, setState] = useState({
        new: [] || JSON.parse(localStorage.getItem("newItem")),
        countries: [],
        open: false,
        snackOpen: false,
    })

    //router
    const search = useLocation().search
    const id = new URLSearchParams(search).get('id')

    const getCountryImage = (country) => {
        let countryImage = ""
        countries.map((item) => {
            if(item.name === country){
                countryImage = item.imgUrl
            }
        })
        return countryImage
    }

    const getNew = () => {
        if(id){
            firebase.db.collection("news").doc(id).onSnapshot(item => {
                let newData = {
                    id: item.id,
                    country: item.data().country,
                    date: item.data().date,
                    destacada: item.data().destacada,
                    newType: item.data().newType,
                    pdf: item.data().pdf,
                    resumen: item.data().resumen,
                    source: item.data().source,
                    title: item.data().title,
                    url: item.data().url,
                    countryImage: getCountryImage(item.data().country)
                }
                setState({...state, new: newData})
            })
        }
    }

    useEffect(() => {
        (localStorage.getItem("newItemId") !== id) ? getNew() : setState({...state, new: JSON.parse(localStorage.getItem("newItem"))});
    },[])

    const getFormattedDate = (date) => {
        let months = [
            "enero",
            "febrero",
            "marzo",
            "abril",
            "mayo",
            "junio",
            "julio",
            "agosto",
            "septiembre",
            "octubre",
            "noviembre",
            "diciembre"
        ]
        return `${parseInt(date.substring(8,10))} de ${months[parseInt(date.substring(5,7))-1]} de ${date.substring(0,4)}`
    }

    const openInNewTab = (url) => {
        const newWindow = window.open(url, "_blank", "noopener,noreferrer");
        if (newWindow) newWindow.opener = null;
    };

    const handleShareChange = () => {
        setState({...state, open: !state.open})
    }

    const handleSnackChange = () => {
        setState({...state, snackOpen: !state.snackOpen})
    }

    return (
        <div>
            {!user && (
                <Redirect to="/"/>
            )}
            <NewHeader />
            <div className={classes.firstNewContainer}>
                <div className={classes.firstNewOverlay}>
                    {state.new.length !== 0 && (
                        <div className={classes.firstNewInnerContainer}>
                            <div className={classes.firstNewCountryContainer}>
                                <div className={classes.flag} style={{backgroundImage: `url(${process.env.PUBLIC_URL}${state.new.countryImage})`, backgroundSize: state.new.country === "Dominicana" ? "cover" : "auto"}}></div>
                                <p className={classes.firstNewCountry}>{state.new.country}</p>
                            </div>
                            <div>
                                <h1 className={classes.firstNewTitle}>{state.new.title}</h1>
                                <p>{state.new.resumen}</p>
                            </div>
                            <div className={classes.firstNewInfoContainer}>
                                <div>
                                    <p className={classes.firstNewSource}>{state.new.source}</p>
                                    <p className={classes.firstNewDate}>{getFormattedDate(state.new.date)}</p>
                                </div>
                                <div>
                                    <IconButton style={{padding: 5}} onClick={() => {openInNewTab(state.new.url)}}>
                                        <LanguageRoundedIcon className={classes.icons}/>
                                    </IconButton>
                                    <IconButton style={{padding: 5}} onClick={() => {openInNewTab(state.new.pdf)}}>
                                        <PictureAsPdfRoundedIcon className={classes.icons}/>
                                    </IconButton>
                                    <IconButton style={{padding: 5}} onClick={handleShareChange}>
                                        <ShareRoundedIcon className={classes.icons}/>
                                    </IconButton>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <Backdrop className={classes.backdrop} open={state.open} onClick={(e) => handleShareChange(e)}>
                <div className={classes.shareContainer} onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                }}>
                    <div className={classes.shareHeaderContainer}>
                        <h3>Comparta la noticia</h3>
                        <IconButton onClick={(e) => handleShareChange(e)}>
                            <CloseRounded className={classes.shareCloseIcon}/>
                        </IconButton>
                    </div>
                    <div className={classes.shareIconContainer}>
                        <FacebookShareButton
                                url={`${window.location.origin.toString()}/noticia?id=${id}`}
                                quote={"title"}
                                className="Demo__some-network__share-button"
                            >
                                <FacebookIcon size={40} round />
                            </FacebookShareButton>
                            <WhatsappShareButton
                                url={`${window.location.origin.toString()}/noticia?id=${id}`}
                                quote={"title"}
                                className="Demo__some-network__share-button"
                            >
                                <WhatsappIcon size={40} round />
                            </WhatsappShareButton>
                            <TwitterShareButton
                                url={`${window.location.origin.toString()}/noticia?id=${id}`}
                                quote={"title"}
                                className="Demo__some-network__share-button"
                            >
                                <TwitterIcon size={40} round />
                            </TwitterShareButton>
                            <EmailShareButton
                                url={`${window.location.origin.toString()}/noticia?id=${id}`}
                                quote={"title"}
                                className="Demo__some-network__share-button"
                            >
                                <EmailIcon size={40} round />
                            </EmailShareButton>
                            <IconButton onClick={() => {
                                navigator.clipboard.writeText(`${window.location.origin.toString()}/noticia?id=${id}`);
                                handleSnackChange()
                            }} style={{background: 'gray', padding: 10}}>
                                <FileCopyRounded style={{fontSize: '20px', color: 'white'}}/>
                            </IconButton>
                    </div>
                </div>
                <Snackbar open={state.snackOpen} autoHideDuration={6000} onClose={handleSnackChange}>
                    <Alert onClose={handleSnackChange} severity="success">
                    Se copió el link exitosamente!
                    </Alert>
                </Snackbar>
            </Backdrop>
        </div>
    )
}
