import { grey } from '@material-ui/core/colors';
import Radio from '@material-ui/core/Radio';
import { withStyles  } from "@material-ui/core/styles";

const WhiteRadio = withStyles({
  root: {
    color: grey[200],
    '&$checked': {
      color: grey[50],
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

export { WhiteRadio }