import React, { useState, useEffect } from 'react'

//firebase
import firebase from '../../database/firebase'

//MaterialUI
import { makeStyles } from "@material-ui/core/styles";
import { IconButton } from '@material-ui/core';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';

//redux
import { useSelector } from 'react-redux';

//Router dome
import { Link, Redirect, useLocation } from "react-router-dom";

//Constants
import countries from '../../constants/countries'

const useStyles = makeStyles((theme) =>({
    backgroundContainer: {
        backgroundImage: 'url(/./img/login/background.jpg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        minHeight: "100vh",
        [theme.breakpoints.up('1200')]: {
            backgroundAttachment: 'fixed'
        }
    },
    overlay: {
        backgroundImage: 'linear-gradient(to bottom, rgba(255,255,255,1)0%, rgba(255,255,255,0.9)50%, rgba(255,255,255,1)100%)',
        paddingTop: "20px",
        paddingLeft: "20px",
        paddingBottom: "50px",
        minHeight: "100vh"
    },
    closeLink: {
      color: 'rgb(20,20,20)',
      '&:hover': {
        color: 'rgb(20,20,20)',
        textDecoration: 'none'
      }
    },
    closeIcon: {
        color: 'rgb(20,20,20)',
        transition: 'transform 450ms',
        '&:hover': {
            transform:'scale(1.06)'
        }
    },
    newContainer: {
        paddingLeft: 10,
        fontSize: 12
    },
    country: {
        marginBottom: 0,
        fontWeight: 'bold',
        textDecoration: 'underline',
        fontFamily: 'Arial',
        fontSize: 18
    },
    company: {
        color: "rgb(59, 204, 250)",
        marginBottom: 0,
        fontFamily: 'Arial',
        fontSize: 18,
        fontWeight: 'bold'
    },
    title: {
        marginBottom: 0,
        fontWeight: 'bold',
        fontFamily: 'Arial',
        fontSize: 18
    },
    resumen: {
        marginBottom: 0,
        fontFamily: 'Arial',
        fontSize: 18
    },
    source: {
        marginBottom: 0,
        fontWeight: 'bold',
        fontFamily: 'Arial',
        fontSize: 16
    },
    url: {
        marginBottom: 0,
        color: '#0002FF',
        textDecoration: 'underline',
        fontFamily: 'Arial',
        fontSize: 18
    },
    errorMessage: {
        color: 'rgb(20,20,20)'
    }
}))

export default function NewsletterResult({classes}) {
    classes = useStyles();

    const user = useSelector(state => state.userState.user);

    //state
    const [state, setState] = useState({
        countries: [],
        news: []
    })

    //router
    const search = useLocation().search
    const type = new URLSearchParams(search).get('type')
    const date = new URLSearchParams(search).get('date')

    const getCountry = (country) => {
        let countryData = null
        countries.map((item) => {
            if(item.name === country){
                countryData = item
            }
        })
        return countryData
    }

    //useEffect
    useEffect(() => {

        firebase.db.collection("news")
            .where("date", "==", date)
            .where("newType", "==", parseInt(type))
            .onSnapshot(snapshot => {
                let data = []
                snapshot.docs.forEach((item) => {
                    let info = {
                        id: item.id,
                        country: item.data().country.toUpperCase(),
                        countryData: getCountry(item.data().country),
                        showCountry: true,
                        showCompany: true,
                        resumen: item.data().resumen,
                        source: item.data().source,
                        company: item.data().company || "",
                        title: item.data().title,
                        url: item.data().url,
                    }
                    data.push(info)
                })
                console.log(data)
                if(type == "0"){
                    data.sort((a,b) => {
                        if(a.countryData.seg < b.countryData.seg){
                            return -1
                        } else{
                            if(a.countryData.seg === b.countryData.seg){
                                if(a.country < b.country){
                                    return -1
                                } else if(a.country === b.country) {
                                    return a.company < b.company ? -1 : 1
                                } else {
                                    return 1
                                }
                            } else{
                                return 1
                            }
                        }
                    })
                } else{
                    data.sort((a,b) => {
                        if(a.countryData.sin < b.countryData.sin){
                            return -1
                        } else{
                            if(a.countryData.sin === b.countryData.sin){
                                return a.country < b.country ? -1 : 1
                            } else{
                                return 1
                            }
                        }
                    })
                }
                data.map((item, i) => {
                    if(i != 0){
                        if(data[i-1].country === item.country){
                            data[i].showCountry = false
                            if(type === "0"){
                                if(data[i-1].company === item.company) {
                                    data[i].showCompany = false
                                }
                            }
                            
                        }
                    }
                })
                setState({...state, news: data})

            })
    },[])


    return (
        <div className={classes.backgroundContainer}>
            <div className={classes.overlay}>
                {!user && (
                    <Redirect to="/"/>
                )}
                {/* mini header */}
                <div>
                    <Link to="/admin/newsletter">
                        <IconButton>
                            <ArrowBackRoundedIcon className={classes.closeIcon}/>
                        </IconButton>
                    </Link>
                </div>
                
                {/* Newsletter */}
                <div className={classes.newsletterContainer}>
                    {state.news.length !== 0 ? (
                        <>
                            {state.news.map((item, i) => (
                                <div key={i} className={classes.newContainer}>
                                    {item.showCountry && (
                                        <p className={classes.country}>{item.country}</p>
                                    )}
                                    {(type === "0" && item.showCompany) && (
                                        <p className={classes.company}>{item.company}</p>
                                    )}
                                    <p className={classes.title}>{item.title}</p>
                                    <p className={classes.resumen}>{item.resumen}</p>
                                    <p className={classes.source}>{item.source}</p>
                                    <p className={classes.url}>{item.url}</p>
                                    <br></br>
                                </div>
                            ))}
                        </>
                    ) : (
                        <div className={classes.newContainer}>
                            <h2 className={classes.errorMessage}>No hay noticias...</h2>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}
