import React, { useEffect, useState } from 'react';

//firebase
import firebase from '../../database/firebase'

//router dom
import { Link, Redirect } from 'react-router-dom'

//MaterialUI
import { makeStyles } from "@material-ui/core/styles";
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

//redux
import { useSelector, useDispatch } from 'react-redux';
import { setUser } from "../../store/actions";

//utils
import { WhiteButton } from '../../utils/Buttons'

//loader
import Loader from "react-loader-spinner";

const useStyles = makeStyles((theme) =>({
    backgroundContainer: {
        minWidth: '100vw',
        minHeight: '100vh',
        backgroundImage: `url(${process.env.PUBLIC_URL}/img/login/background.jpg)`,
        backgroundSize: 'cover',
        backgroundPosition: 'center'
    },
    overlay: {
        width: '100vw',
        height: '100vh',
        backgroundImage: 'linear-gradient(to bottom, rgba(20,20,20,1)0%, rgba(20,20,20,0.8)50%, rgba(20,20,20,1)100%)',
        textAlign: 'center'
    },
    logoImg: {
        width: '80px',
        position: 'relative',
        top: '20px'
    },
    formContainer: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        padding: '50px',
        width: '90%',
        maxWidth: '500px'
    },
    passwordInput: {
        flex: '1',
        width: '100%',
        padding: '15px',
        outline: 'none',
        borderRadius: '25px',
        border: 'none',
        background: "none",
        color: 'white',
        '&::placeholder': {
            color: 'rgba(255,255,255,0.75)'
        }
    },
    userInput: {
        width: '100%',
        padding: '15px',
        outline: 'none',
        borderRadius: '25px',
        border: 'none',
        marginBottom: '20px',
        background: "rgba(255,255,255,0.25)",
        color: 'white',
        '&::placeholder': {
            color: 'rgba(255,255,255,0.75)'
        }
    },
    passwordContainer: {
        display: 'flex',
        width: '100%',
        background: 'rgba(255,255,255,0.25)',
        borderRadius: '25px',
        border: 'none',
        marginBottom: '20px',
    },
    errorContainer: {
        background: 'rgba(255,255,255,0.25)',
        borderRadius: 10,
        padding: 20,
        marginTop: 20
    },
    errorText: {
        color: 'white',
        fontSize: '25px'
    },
    link: {
        color: 'white',
        '&:hover': {
            color: 'white'
        }
    },
    loaderContainer:  {
        margin: 'auto',
        width: 80,
        paddingTop: '30px'
    }
}))

export default function Index({classes}) {
    classes = useStyles();
    
    //redux
    const dispacth = useDispatch();
    const user = useSelector(state => state.userState.user);

    //State
    const [values, setValues] = useState({
        email: '',
        password: '',
        showPassword: false,
        loading: false,
        error: ""
    });

    //useEffect
    useEffect(() => {


        const unsuscribe = firebase.firebase.auth().onAuthStateChanged(userData => {
            if(userData != null){
                //check if user is in redux
                if(user == null){
                    let tempUser = JSON.parse(localStorage.getItem("user")) || null
                    if(tempUser != null){
                        dispacth(setUser({
                            email: tempUser.email,
                            type: tempUser.type,
                            id: tempUser.id,
                            excludedCountries: tempUser.excludedCountries
                        }))
                    } else{
                        firebase.db.collection("users").doc(userData.uid).onSnapshot(snapshot => {
                            dispacth(setUser({
                                email: snapshot.data().email,
                                type: snapshot.data().type,
                                id: userData.uid,
                                excludedCountries: snapshot.data().excludedCountries
                            }));
                        })
                    }
                }
            }

        })
        return unsuscribe
    },[])

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleMouseDownPassword = (e) => {
        e.preventDefault();
    };

    const handleLogin = (e) => {
        e.preventDefault();
        setValues({...values, loading: true})

        const { email, password } = values;

        firebase.firebase.auth().signInWithEmailAndPassword(email, password)
        .then((result) => {
            setValues({...values, error: ""})
            firebase.db.collection("users").doc(result.user.uid).onSnapshot(snapshot => {
                dispacth(setUser({
                    email: snapshot.data().email,
                    type: snapshot.data().type,
                    id: result.user.uid,
                    excludedCountries: snapshot.data().excludedCountries
                }));
            })
            

        })
        .catch((error) => {
            //console.log(error.message)
            var errorMessage = error.message
            if(errorMessage === "There is no user record corresponding to this identifier. The user may have been deleted."){
                errorMessage = "No existe el usuario"
            } else if(errorMessage === "The email address is badly formatted."){
                errorMessage = "Formato de email incorrecto"
            } else{
                errorMessage = "Contraseña incorrecta. Favor de ingresarla de nuevo."
            }
            setValues({...values, error: errorMessage})
        })

    }

    return (
        <div className={classes.backgroundContainer}>
            <div className={classes.overlay}>
                <img alt="Logo" className={classes.logoImg} src={process.env.PUBLIC_URL + "/img/logo/logo.png"}/>
                <form className={classes.formContainer}>
                    <div style={{display: 'flex'}}>
                        <input 
                            type="text" 
                            placeholder="Usuario"
                            autoCapitalize="none"
                            value={values.email}
                            onChange={(e) => setValues({...values, email: e.target.value})}
                            className={classes.userInput}
                        />
                    </div>
                    
                    <div className={classes.passwordContainer}>
                        <input 
                            type={values.showPassword ? 'text' : 'password'}
                            placeholder="Contraseña"
                            autoCapitalize="none"
                            value={values.password}
                            onChange={(e) => setValues({...values, password: e.target.value})}
                            className={classes.passwordInput}
                        />
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            >
                            {values.showPassword ? <Visibility  style={{color: 'white'}}/> : <VisibilityOff style={{color: 'white'}}/>}
                        </IconButton>
                    </div>
                    <div>
                        <WhiteButton type="submit" onClick={handleLogin}>Iniciar sesión</WhiteButton>
                        <Link to="/forgot-password" className={classes.link}>¿Olvidaste la contraseña?</Link>
                    </div>
                    {values.error !== "" && (
                        <div className={classes.errorContainer}>
                            <h2 className={classes.errorText}>{values.error}</h2>
                        </div>
                    )}
                    {/* Loading */}
                    {values.loading && (
                        <div className={classes.loaderContainer}>
                            <Loader  type="BallTriangle" color="#FFFFFF" height={80} width={80} />
                        </div>
                    )}


                    {/* redirect */}
                    {user && (
                        <>
                            {user.type === "admin" ? (
                                <Redirect to="/admin"/>
                            ) : (
                                <Redirect to="/inicio"/>
                            )}
                        </>
                    )}
                </form>
            </div>
        </div>
    )
}
