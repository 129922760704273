import React, { useEffect, useState } from 'react'
//Link
import { Redirect, useHistory, useLocation } from "react-router-dom";

//MaterialUI
import { makeStyles  } from "@material-ui/core/styles";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';

//Components
import { TransparentInput } from '../../utils/Select'
import { WhiteButton } from '../../utils/Buttons';
import SearchHeader from './Header/SearchHeader'

//redux
import { useSelector } from 'react-redux';

//Constants
import countries from '../../constants/countries'

const useStyles = makeStyles((theme) =>({
    backgroundContainer: {
        width: "100vw",
        height: "100vh",
        [theme.breakpoints.up('992')]: {
            minHeight: '800px',
        },
        backgroundImage: `url(${process.env.PUBLIC_URL}/img/news/img5.jpg)`,
        backgroundSize: "cover",
        backgroundPosition: "center",
    },
    backgroundOverlay: {
        width: "100%",
        height: "100vh",
        [theme.breakpoints.up('992')]: {
            minHeight: '800px',
        },
        backgroundImage: 'linear-gradient(to bottom, rgba(20,20,20,1)0%, rgba(20,20,20,0.8)50%, rgba(20,20,20,1)100%)',
        padding: '300px 10px 50px 10px',
        [theme.breakpoints.down('576')]: {
            padding: '100px 5px 50px 5px',
        }
    },
    formContainer: {
        background: "none",
        width: '98%',
        maxWidth: '400px',
        margin: 'auto'
    },
    inputContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 30
    },
    labelsContainer: {
        background: "none",
    },
    inputsContainer: {
        background: "none"
    },
    inputLabel: {
        marginBottom: 0,
        fontWeight: 'bold',
        color: 'white',
        fontSize: '20px',
        [theme.breakpoints.down('576')]: {
            fontSize: '16px'
        }
    },
    input: {
        width: '100%',
        padding: '12px',
        outline: 'none',
        borderRadius: '25px',
        border: 'none',
        background: "rgba(255,255,255,0.25)",
        color: 'white'
    },
    inputDescription: {
        color: 'white',
        marginBottom: 0
    },
    searchLink: {
        color: 'rgb(20,20,20)',
        '&:hover': {
            textDecoration: 'none',
            color: 'rgb(20,20,20)'
        }
    },
    errorContainer: {
        background: "rgba(255, 255, 255,0.15)",
        borderRadius: "10px",
        padding: 20,
        color: 'white',
        position: 'relative',
        fontWeight: 'bold',
        marginTop: 20
    }
}))

export default function Search({classes}) {
    classes = useStyles()
    
    //redux
    const user = useSelector(state => state.userState.user);

    //router
    const history = useHistory()
    const search = useLocation().search
    const country = new URLSearchParams(search).get('country')
    const keyword = new URLSearchParams(search).get('keyword')
    const date1 = new URLSearchParams(search).get('date1')
    const date2 = new URLSearchParams(search).get('date2')

    //state
    const [state, setState] = useState({
        countriesList: [],
        country: "",
        keyword: "",
        date1: "",
        date2: "",
        error: ""
    })

    useEffect(() => {
        countries.sort((a, b) => (a.name < b.name ? -1 : 1))
        setState({...state, 
            countriesList: countries,
            country: country || "",
            keyword: keyword ? keyword.replaceAll("_", "  ") : "",
            date1: date1 || "",
            date2: date2 || "",
        })
    },[])

    const handleCountryChange = (event) => {
        localStorage.setItem("country", event.target.value)
        setState({...state, country: event.target.value})
    }

    const handleInputChange = (event) => {
        let value = event.target.value
        let name = event.target.name
        setState({...state, [name]: value})
    }

    const validate = () => {
        if(state.country !== ""){
            return true;
        }
        if(state.keyword !== ""){
            return true;
        }
        if(state.date1 !== ""){
            return true;
        }
        if(state.date2 !== ""){
            return true;
        }
        if(state.date1 > state.date2){
            setState({...state, error: "La fecha final debe ser después que la fecha inicial"})
            return false
        }
        setState({...state, error: "Ingrese algún parámetro de búsqueda"})
        return false
    }

    const getDate = () => {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();
        today = yyyy + '-' + mm + '-' + dd;
        return today;
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        e.stopPropagation()

        if(validate()){
            setState({...state, error: ""})
            let keywords = state.keyword.toLocaleLowerCase()
            keywords = keywords.replaceAll("    ", "_")
            keywords = keywords.replaceAll("   ", "_")
            keywords = keywords.replaceAll("  ", "_")
            keywords = keywords.replaceAll(" ", "_")
            keywords = keywords.replaceAll(",", "")
            keywords = keywords.replaceAll("&", "and")
            history.push(`/inicio?country=${state.country}&keyword=${keywords}&date1=${state.date1 ? state.date1 : "2019-02-21"}&date2=${state.date2 ? state.date2 : getDate()}`)
        }
    }

    return (
        <>
            {!user && (
                <Redirect to="/"/>
            )}
            <SearchHeader/>
            <div className={classes.backgroundContainer}>
                <div className={classes.backgroundOverlay}>
                    
                    {/* form */}
                    <form className={classes.formContainer}>
                        {/* Pais */}
                        <div className={classes.inputContainer}>
                            <div className={classes.labelsContainer}>
                                <p className={classes.inputLabel}>País</p>
                            </div>
                            <div className={classes.inputsContainer}>
                                
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        value={state.country}
                                        onChange={handleCountryChange}
                                        label="Pais"
                                        input={<TransparentInput/>}
                                    >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    {state.countriesList && state.countriesList.map((item, i) => (
                                        <MenuItem key={i} value={item.name}>{item.name}</MenuItem>
                                    ))}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        {/* Palabra Clave */}
                        <div className={classes.inputContainer}>
                            <div className={classes.labelsContainer}>
                                <p className={classes.inputLabel}>Palabra(s) Clave</p>
                                <p className={classes.inputDescription}>Separadas por un espacio</p>
                            </div>
                            <div className={classes.inputsContainer}>
                                <input name="keyword" onChange={handleInputChange} value={state.keyword}  className={classes.input}/>
                            </div>
                        </div>
                        {/* Fecha inicio */}
                        <div className={classes.inputContainer}>
                            <div className={classes.labelsContainer}>
                                <p className={classes.inputLabel}>Fecha Inicio</p>
                            </div>
                            <div className={classes.inputsContainer}>
                                <input value={state.date1} type="date" name="date1" onChange={handleInputChange}  className={classes.input}/>
                            </div>
                        </div>
                        {/* Fecha inicio */}
                        <div className={classes.inputContainer}>
                            <div className={classes.labelsContainer}>
                                <p className={classes.inputLabel}>Fecha Final</p>
                            </div>
                            <div className={classes.inputsContainer}>
                                <input value={state.date2} type="date" name="date2" onChange={handleInputChange}  className={classes.input}/>
                            </div>
                        </div>
                        <WhiteButton type="submit" onClick={e => {handleSubmit(e)}}>Buscar</WhiteButton>
                        {/* <Link className={classes.searchLink} to={`/inicio?country=${state.country ? state.country : null }&keyword=${state.keyword ? state.keyword : null }&date1=${state.date1 ? state.date1 : null }&date2=${state.date2 ? state.date2 : null }`}>
                            <WhiteButton onClick={e => {handleSubmit(e)}}>Buscar</WhiteButton>
                        </Link> */}
                        {state.error !== "" && (
                            <div className={classes.errorContainer}>
                                <h4>{state.error}</h4>
                            </div>
                        )}
                        
                    </form>
                </div>
            </div>
        </>
    )
}
