import React from "react"
import { BrowserRouter, Switch, Route } from "react-router-dom";


//Global
import Login from './Pages/Login'
import ForgotPassword from './Pages/Login/ForgotPassword'

//User
import Home from './Pages/User/Home'
import New from './Pages/User/New'
import Search from './Pages/User/Search'
import Gerencia from './Pages/User/Gerencia'
import Preferences from './Pages/User/Preferences'
import ResetPassword from './Pages/User/ResetPassword'

//Admin
import AdminIndex from './Pages/Admin'
import Register from './Pages/Admin/Register'
import Noticias from './Pages/Admin/Noticias'
import Newsletter from './Pages/Admin/Newsletter'
import NewsletterResult from './Pages/Admin/NewsletterResult'
import Resumen from './Pages/Admin/Resumen'


const Router = () => {
    return (
        <BrowserRouter>
            <Switch>
                {/* Auth */}
                <Route exact path="/" component={Login}/>
                <Route exact path="/forgot-password" component={ForgotPassword}/>

                {/* User */}
                <Route exact path="/inicio" component={Home}/>
                <Route exact path="/noticia" component={New}/>
                <Route exact path="/buscar" component={Search}/>
                <Route exact path="/gerencia" component={Gerencia}/>
                <Route exact path="/preferencias" component={Preferences}/>
                <Route exact path="/reset-password" component={ResetPassword}/>

                {/* Admin */}
                <Route exact path="/admin" component={AdminIndex}/>
                <Route exact path="/admin/edit-users" component={Register}/>
                <Route exact path="/admin/noticias" component={Noticias}/>
                <Route exact path="/admin/resumen" component={Resumen}/>
                <Route exact path="/admin/newsletter" component={Newsletter}/>
                <Route exact path="/admin/newsletter-result" component={NewsletterResult}/>
            </Switch>
        </BrowserRouter>
    )
}

export default Router;