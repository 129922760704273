import { Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";


const BlackButton = withStyles((theme) => ({
  root: {
    color: "white",
    backgroundColor: "black",
    borderRadius: "100px",
    transition: "transform 450ms",
    fontWeight: "bolder",
    padding: "15px 20px",
    marginBottom: '20px',
    width: "100%",
    '&:hover': {
      backgroundColor: "black",
      transform: "scale(1.02)",
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: "black",
      border: 'none',
    },
    '&:focus': {
      backgroundColor: "black",
    },
    '&:disabled': {
        opacity: '0.7',
        color: 'black'
    }
  },
}))(Button);

const GreyButton = withStyles((theme) => ({
  root: {
    color: "white",
    backgroundColor: "rgb(20, 20, 20)",
    borderRadius: "25px",
    transition: "transform 450ms",
    fontWeight: "bolder",
    padding: "15px 20px",
    marginBottom: '20px',
    width: "100%",
    top: "10px",
    
    '&:hover': {
      backgroundColor: "rgb(30, 30, 30)",
      transform: "scale(1.02)",
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: "rgb(30, 30, 30)",
      border: 'none',
    },
    '&:focus': {
      backgroundColor: "rgb(30, 30, 30)",
    },
  },
}))(Button);

const WhiteButton = withStyles((theme) => ({
  root: {
    color: "rgb(20,20,20)",
    backgroundColor: "rgb(255, 255, 255)",
    borderRadius: "25px",
    transition: "transform 450ms",
    fontWeight: "bolder",
    padding: "15px 20px",
    marginBottom: '20px',
    width: "100%",
    top: "10px",
    
    '&:hover': {
      backgroundColor: "rgb(245, 245, 255)",
      transform: "scale(1.02)",
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: "rgb(255, 255, 255)",
      border: 'none',
    },
    '&:focus': {
      backgroundColor: "rgb(255, 255, 255)",
    },
  },
}))(Button);

export {BlackButton, GreyButton, WhiteButton}