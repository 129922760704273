import React, { useEffect, useState } from 'react'

//MaterialUI
import { makeStyles  } from "@material-ui/core/styles";
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

//Link
import { Link, useLocation } from "react-router-dom";

//Components

//Menu drawer
import { IconButton } from '@material-ui/core';

const useStyles = makeStyles(() =>({
    headerContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '10px',
        backgroundImage: 'linear-gradient(to bottom, rgba(20,20,20,1)0%, rgba(20,20,20,1)100%)',
        position: "fixed",
        top: 0,
        width: "100vw",
        height: "70px",
        zIndex: 99,
    },
    logoContainer: {
        flex: '1',
        backgroundColor: "none",
        textAlign: "center"
    },
    logoImg: {
        width: '80px',
        margin: "auto",
        height: '48px',
        position: 'relative',
        left: '5px'
    },
    hamburguerMenu: {
        backgroundColor: `${"white"} !important`,
    },
    menuIcon: {
        color: 'white',
        transition: 'transform 450ms',
        fontSize: '30px',
        '&:hover': {
            transform: 'scale(1.2)'
        }
    }
}))



export default function NewHeader({classes}) {
    classes = useStyles();

    //state
    const [state, setState] = useState({
        searching: false
    })

    //router
    const search = useLocation().search
    const country = new URLSearchParams(search).get('country')
    const keyword = new URLSearchParams(search).get('keyword')
    const date1 = new URLSearchParams(search).get('date1')
    const date2 = new URLSearchParams(search).get('date2')

    const isSearch = () => {
        if(country !== null) return true;
        if(keyword !== null) return true;
        if(date1 !== null) return true;
        if(date2 !== null) return true;
        return false
    }

    useEffect(() => {
        setState({...state, searching: isSearch()})
    },[])
    
    return (
        <div id={"header"}>
            <div className={classes.headerContainer}>
                <div>
                    <Link to={(state.searching) ? `inicio?country=${country || ""}&keyword=${keyword || ""}&date1=${date1 || "2019-02-21"}&date2=${date2 || ""}` : "/inicio"}>
                        <IconButton>
                            <CloseRoundedIcon className={classes.menuIcon}/>
                        </IconButton>
                    </Link>
                    
                </div>
                <div className={classes.logoContainer}>
                    <Link to={(state.searching) ? `inicio?country=${country || ""}&keyword=${keyword || ""}&date1=${date1 || "2019-02-21"}&date2=${date2 || ""}` : "/inicio"}>
                        <img alt="Logo" className={classes.logoImg} src={process.env.PUBLIC_URL + "/img/logo/logo.png"}/>
                    </Link>
                </div>
                <div>
                    <Link to={(state.searching) ? `buscar?country=${country || ""}&keyword=${keyword || ""}&date1=${date1 || "2019-02-21"}&date2=${date2 || ""}` : "/buscar"}>
                        <IconButton>
                            <SearchRoundedIcon className={classes.menuIcon}/>
                        </IconButton>
                    </Link>
                </div>
            </div>          
        </div>
    )
}