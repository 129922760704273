import firebase from 'firebase';
import 'firebase/firestore';
import 'firebase/firebase-storage'

var firebaseConfig = {
    apiKey: "AIzaSyBrDNjeHoityZcgE-N9uizjRAghnDGEue4",
    authDomain: "newsbi-6d91a.firebaseapp.com",
    projectId: "newsbi-6d91a",
    storageBucket: "newsbi-6d91a.appspot.com",
    messagingSenderId: "409657399511",
    appId: "1:409657399511:web:98d585424650c83e6b7892"
};

if(firebase.apps.length === 0){
    firebase.initializeApp(firebaseConfig);
}

const db = firebase.firestore();
const storage = firebase.storage();
export default { firebase, db, storage };


