//Firebase
import firebase from '../../database/firebase'

import { SET_USER, LOGOUT } from "./actionTypes";

export const setUser = (user) => {
    localStorage.setItem("user", JSON.stringify(user));
    return {
        type: SET_USER,
        data: user
    }
}

export const logout = () => {
    localStorage.setItem("user", null);
    firebase.firebase.auth().signOut()
    return {
        type: LOGOUT
    }
}
