import React, { useState } from 'react'

//Components
import Header from './Header'
import { WhiteRadio } from '../../utils/RadioButtons';
import { WhiteButton } from '../../utils/Buttons'

//MaterialUI
import { makeStyles } from "@material-ui/core/styles";
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';

//redux
import { useSelector } from 'react-redux';

//Router dome
import { Link, Redirect } from "react-router-dom";


const useStyles = makeStyles((theme) =>({
    backgroundContainer: {
        backgroundImage: 'url(/./img/login/background.jpg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        minHeight: "100vh"
    },
    overlay: {
        backgroundImage: 'linear-gradient(to bottom, rgba(20,20,20,1)0%, rgba(20,20,20,0.9)50%, rgba(20,20,20,1)100%)',
        paddingBottom: "50px",
        minHeight: "100vh"
    },
    title: {
        color: "white",
        textAlign: "center",
        fontWeight: "bold",
        marginBottom: "50px",
        [theme.breakpoints.down('576')]: {
            fontSize: 30
        }
    },
    formContainer: {
        width: "95%",
        maxWidth: "600px",
        margin: "auto",
        background: "rgba(255, 255, 255,0.05)",
        padding: "20px",
        borderRadius: "10px",
        paddingTop: "35px"
    },
    input: {
        width: '100%',
        padding: '15px',
        outline: 'none',
        borderRadius: '25px',
        border: 'none',
        marginBottom: '30px',
        color: 'white',
        background: "rgba(255,255,255,0.25)",
        '&::placeholder': {
            color: 'rgba(255,255,255,0.75)'
        }
    },
    generateLink: {
        color: 'rgb(20,20,20)',
        '&:hover': {
            textDecoration: 'none',
            color: 'rgb(20,20,20)',
        }
    }
}))

const getDate = () => {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();
        if(today.getHours() > 16){
            today.setDate(today.getDate() + 1)
            dd = String(today.getDate()).padStart(2, '0');
            mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            yyyy = today.getFullYear()
        }
        today = yyyy + '-' + mm + '-' + dd;
        return today;
    }

export default function Newsletter({classes}) {
    classes = useStyles();

    const user = useSelector(state => state.userState.user);

    //state
    const [state, setState] = useState({
        date: getDate(),
        newType: localStorage.getItem("newType") || "Seguimiento a la Competencia",
    })

    const handleInputChange = (event) => {
        let value = event.target.value
        let name = event.target.name
        localStorage.setItem(`${name}`, value)
        setState({...state, [name]: value})
    }

    const handleNewTypeChange = (event) => {
        setState({...state, newType: event.target.value})
    }

    return (
        <div className={classes.backgroundContainer}>
            <div className={classes.overlay}>
                {!user && (
                    <Redirect to="/"/>
                )}
                <Header/>
                <h1 className={classes.title}>Genera Newsletter chavin</h1>
                
                <div className={classes.formContainer}>
                    {/* Fecha */}
                    <input value={state.date} type="date" name="date" onChange={handleInputChange}  className={classes.input}/>

                    {/* Newsletter */}
                    <FormLabel component="legend" style={{color: "white", fontSize: "20px", fontWeight: 'bolder'}}>Newsletter Noticia</FormLabel>
                    <RadioGroup aria-label="gender" name="user_type" value={state.newType} onChange={handleNewTypeChange}>
                        <FormControlLabel style={{color: "white", fontSize: "20px", fontWeight: 'bolder'}} value="Seguimiento a la Competencia" control={<WhiteRadio  />} label="Seguimiento a la Competencia" />
                        <FormControlLabel style={{color: "white", fontSize: "20px", fontWeight: 'bolder'}} value="Síntesis Regional" control={<WhiteRadio />} label="Síntesis Regional" />
                    </RadioGroup>

                    {/* Generate */}
                    <Link className={classes.generateLink} to={`/admin/newsletter-result?type=${state.newType === "Seguimiento a la Competencia" ? 0 : 1}&date=${state.date}`}>
                        <WhiteButton>Generar newsletter</WhiteButton>
                    </Link>
                </div>
            </div>
        </div>
    )
}
