import React, { useEffect, useState } from 'react'
import firebase from '../../database/firebase'

//Link
import { Redirect } from "react-router-dom";

//MaterialUI
import { makeStyles  } from "@material-ui/core/styles";
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import { IconButton } from '@material-ui/core';

//Components
import SearchHeader from './Header/SearchHeader'
import { WhiteButton } from '../../utils/Buttons'

//redux
import { useSelector, useDispatch } from 'react-redux';
import {  setUser } from '../../store/actions'

//bootstrap
import { Col, Row } from 'react-bootstrap';

//Constants
import countries from '../../constants/countries'

const useStyles = makeStyles((theme) =>({
    backgroundContainer: {
        width: "100vw",
        [theme.breakpoints.up('992')]: {
            minHeight: '100vh',
        },
        backgroundImage: `url(${process.env.PUBLIC_URL}/img/news/img5.jpg)`,
        backgroundSize: "cover",
        backgroundPosition: "center",
    },
    backgroundOverlay: {
        width: "100%",
        [theme.breakpoints.up('992')]: {
            minHeight: '100vh',
        },
        backgroundImage: 'linear-gradient(to bottom, rgba(20,20,20,1)0%, rgba(20,20,20,0.8)50%, rgba(20,20,20,1)100%)',
        padding: '70px 10px 50px 10px',
        [theme.breakpoints.down('576')]: {
            padding: '50px 5px 50px 5px',
        }
    },
    container: {
        width: '100%',
        maxWidth: 1000,
        margin: 'auto'
    },
    countriesContainer: {
        width: '98%',
        margin: 'auto',
        background: 'rgba(255,255,255,0.15)',
        padding: 20,
        borderRadius: 10,
        marginBottom: 30
    },
    subtitle: {
        color: 'white',
        textAlign: 'center',
        fontWeight: 'bold',
        marginBottom: 20
    },
    countryContainer: {
        display: "flex",
        marginBottom: "20px",
        alignItems: "center",
        background: "rgba(255, 255, 255,0.05)",
        padding: "10px",
        borderRadius: "10px"
    },
    flag: {
        width: "64px",
        height: "40px",
        borderRadius: "5px",
        backgroundSize: "cover",
        backgroundPosition: "center",
        marginRight: "20px"
    },
    flagName: {
        flex: "1",
        color: "white",
        fontWeight: "bold"
    },
    deleteIcon: {
        fontSize: "30px", 
        color: "white",
        transition: 'transform 450ms',
        '&:hover':{
            transform: 'scale(1.2)'
        }
    },
    errorContainer: {
        background: "rgba(255, 255, 255,0.15)",
        borderRadius: "10px",
        padding: 20,
        color: 'white',
        position: 'relative',
        fontWeight: 'bold',
        marginTop: 20
    }
}))

export default function Preferencias({classes}) {
    classes = useStyles()
    
    //redux
    const dispatch = useDispatch()
    const user = useSelector(state => state.userState.user);

    //state
    const [state, setState] = useState({
        excluded: [],
        included: [],
        status: [],
        succes: ""
    })

    const fecthData = (num) => {
        let included = []
        let excluded = []

        let excludedCountries = []
        firebase.db.collection("users").doc(user.id).onSnapshot(data => {
            data.data().excludedCountries.map((country) => {
                excludedCountries.push(country)
            })
            countries.forEach((item) => {
                if(excludedCountries.indexOf(item.name) === -1){
                    included.push(item)
                } else{
                    excluded.push(item)
                }
            })
            
            included.sort((a, b) => (a.name < b.name ? -1 : 1))
            excluded.sort((a, b) => (a.name < b.name ? -1 : 1))
            setState({...state, excluded: excluded, included: included, succes: num === 0 ? "" : "Se guardaron los datos correctamente"})
        })
    }

    useEffect(() => {
        fecthData(0)
    },[])

    const handleInclude = (country, i) => {
        let includedCountries = [...state.included]
        let excludedCountries = [...state.excluded]
        excludedCountries.splice(i, 1)
        includedCountries.push(country)

        excludedCountries.sort((a, b) => (a.name < b.name ? -1 : 1))
        includedCountries.sort((a, b) => (a.name < b.name ? -1 : 1))
        setState({...state, excluded: excludedCountries, included: includedCountries})
    }

    const handleExclude = (country, i) => {
        let includedCountries = [...state.included]
        let excludedCountries = [...state.excluded]
        includedCountries.splice(i, 1)
        excludedCountries.push(country)

        excludedCountries.sort((a, b) => (a.name < b.name ? -1 : 1))
        includedCountries.sort((a, b) => (a.name < b.name ? -1 : 1))
        setState({...state, excluded: excludedCountries, included: includedCountries})
    }

    const handleSave = (e) => {
        e.preventDefault()
        e.stopPropagation()
        //save data
        let excludedCountries = []
        state.excluded.map((country) => {
            excludedCountries.push(country.name)
        })
        firebase.db.collection("users").doc(user.id).set({
            excludedCountries: excludedCountries
        },{
            merge: true,
        });
        let newUser = {
            email: user.email,
            id: user.id,
            type: user.type,
            excludedCountries: excludedCountries
        }
        dispatch(setUser(newUser))
        

        let uniqueExcluded = state.excluded.filter((c, index) => {
            return state.excluded.indexOf(c) === index;
        });
        let uniqueIncluded = state.included.filter((c, index) => {
            return state.included.indexOf(c) === index;
        });
        localStorage.setItem("countriesChange", 1)
        setState({...state,  excluded: uniqueExcluded, included: uniqueIncluded, succes: "Se guardaron los datos correctamente"})
        fecthData(1)
        //reset()
    }

    return (
        <>
            {!user && (
                <Redirect to="/"/>
            )}
            {user === "" && (
                <Redirect to="/"/>
            )}
            <SearchHeader/>
            <div className={classes.backgroundContainer}>
                <div className={classes.backgroundOverlay}>
                    <Row className={classes.container}>
                        {/* Not included */}
                        <Col lg={6}>
                            <div className={classes.countriesContainer}>
                                <h3 className={classes.subtitle}>Excluidos</h3>
                                {/* list of countries */}
                                {state.excluded.length !== 0 ? (
                                    <>
                                        {state.excluded.map((country, i) => (
                                            <div key={i} className={classes.countryContainer}>
                                                <div className={classes.flag} style={{backgroundImage: `url(${process.env.PUBLIC_URL}${country.imgUrl})`, backgroundSize: country === "Dominicana" ? "cover" : "auto"}}></div>

                                                <h4 className={classes.flagName}>{country.name}</h4>
                                                <IconButton onClick={() => {handleInclude(country, i)}}>
                                                    <AddCircleRoundedIcon className={classes.deleteIcon}/>
                                                </IconButton>
                                            </div>
                                        ))}
                                    </>
                                ) : (
                                    <>
                                        <div className={classes.errorContainer}>
                                            <h4>No hay países...</h4>
                                        </div>
                                    </>
                                )}
                                {/* Save data */}
                                <WhiteButton onClick={(e) => {handleSave(e)}}>Guardar datos</WhiteButton>
                                {state.succes !== "" && (
                                    <div className={classes.errorContainer}>
                                        <h4>{state.succes}</h4>
                                    </div>
                                )}
                            </div>
                        </Col>

                        {/* Included */}
                        <Col lg={6}>
                            <div className={classes.countriesContainer}>
                                <h3 className={classes.subtitle}>Incluidos</h3>
                                {/* list of countries */}
                                {state.included.length !== 0 ? (
                                    <>
                                        {state.included.map((country, i) => (
                                            <div key={i} className={classes.countryContainer}>
                                                <div className={classes.flag} style={{backgroundImage: `url(${country.imgUrl})`}}></div>

                                                <h4 className={classes.flagName}>{country.name}</h4>
                                                <IconButton onClick={() => {handleExclude(country, i)}}>
                                                    <DeleteRoundedIcon className={classes.deleteIcon}/>
                                                </IconButton>
                                            </div>
                                        ))}
                                    </>
                                ) : (
                                    <>
                                        <div className={classes.errorContainer}>
                                            <h4>No hay países...</h4>
                                        </div>
                                    </>
                                )}
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    )
}
