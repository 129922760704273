import React, { useEffect, useState } from 'react'

//Link
import { Redirect } from "react-router-dom";

//MaterialUI
import { makeStyles  } from "@material-ui/core/styles";

//Components
import SearchHeader from './Header/SearchHeader'

//redux
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) =>({
    backgroundContainer: {
        width: "100vw",
        height: "calc(100vh -70px)",
        [theme.breakpoints.up('992')]: {
            minHeight: '800px',
        },
        backgroundImage: `url(./img/news/img5.jpg)`,
        backgroundSize: "cover",
        backgroundPosition: "center",
    },
    backgroundOverlay: {
        width: "100%",
        height: "calc(100vh -70px)",
        backgroundImage: 'linear-gradient(to bottom, rgba(20,20,20,1)0%, rgba(20,20,20,0.8)50%, rgba(20,20,20,1)100%)',
        padding: '100px 10px 0px 10px',
        [theme.breakpoints.up('992')]: {
            minHeight: '800px',
        },
        [theme.breakpoints.down('576')]: {
            padding: '50px 10px 50px 10px',
        }
    },
    infoContainer: {
        height: 'calc(100vh - 170px)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        paddingBottom: '30px'
    },
    title: {
        textAlign: 'center',
        color: 'white',
        fontWeight: 'bold'
    },
    paragraph: {
        textAlign: 'center',
        color: 'white',
        maxWidth: '250px',
        margin: 'auto'
    },
    copyright: {
        textAlign: 'center',
        color: 'white',
        margin: 'auto'
    }
}))

export default function Search({classes}) {
    classes = useStyles()

    const  [year, setYear] =  useState("");
    
    const user = useSelector(state => state.userState.user);

    useEffect(() => {
        //set current year
        let today = new  Date;
        
        setYear(today.getFullYear())
    },[])

    return (
        <>
            {!user && (
                <Redirect to="/"/>
            )}
            <SearchHeader/>
            <div className={classes.backgroundContainer}>
                <div className={classes.backgroundOverlay}>
                    <div className={classes.infoContainer}>
                        <div>
                            <h2 className={classes.title}>ACERCA DE</h2>
                        </div>
                        <div>
                            <p className={classes.paragraph}>
                                Aplicación Distribuida por la Gerencia de Marketing Analytics de  Radiomóvil Dipsa S.A. de C.V.
                            </p>
                            <p className={classes.paragraph}>
                                <b>Versión 1.1.3</b>
                            </p>
                        </div>
                        <div>
                            <p className={classes.copyright}>
                                Copyright © {year !== "" && year} ASERCOM. Derechos Reservados.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
