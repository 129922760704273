import React, { useState, useEffect } from 'react';

//firebase
import firebase from "../../database/firebase";

//MaterialUI
import { makeStyles } from "@material-ui/core/styles";
import { PersonRounded } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import { WhiteButton } from '../../utils/Buttons';

const useStyles = makeStyles((theme) =>({
    root: {
        width: '100%',
        '& > * + *': {
        marginTop: theme.spacing(2),
        },
    },
    formContainer: {
        padding: '50px',
        width: '90%',
        maxWidth: '600px',
        background: "rgba(255, 255, 255,0.05)",
        borderRadius: "20px",
        margin: 'auto',
        color: 'white',
        [theme.breakpoints.down('576')]: {
            padding: 10
        }
    },
    input: {
        width: '100%',
        padding: '15px',
        outline: 'none',
        borderRadius: '25px',
        border: 'none',
        marginBottom: '20px',
        background: 'rgba(255,255,255,0.25)',
        color: 'white',
        '&::placeholder': {
            color: 'rgba(255,255,255,0.75)'
        }
    },
    userContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        background:'rgba(255,255,255,0.25)',
        padding: 10,
        borderRadius: 10,
        marginBottom: 10
    },
    userText: {
        marginBottom: 0,
        lineHeight: 3,
        [theme.breakpoints.down('576')]: {
            fontSize: 12
        }
    },
    userIcon: {
        color: 'white',
        transition: 'transform 450ms',
        marginBottom: 0,
        '&:hover': {
            transform: 'scale(1.2)'
        }
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    modalContainer: {
        background: 'rgb(20,20,20)',
        padding: 20,
        borderRadius: 10,
        width: '90%',
        maxWidth: '600px'
    },
    modalHeaderContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    shareCloseIcon: {
        color: 'white',
        fontSize: 35,
        transition: 'transform 450ms',
        '&:hover': {
            transform: 'scale(1.2)'
        }
    },
    optionsContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: 20
    },
    button: {
        borderRadius: 25,
        transition: 'transform 450ms',
        '&:hover': {
            transform: 'scale(1.08)'
        }
    }
}))

export default function EditUsers({classes}) {
    classes = useStyles();
    const [state, setState] = useState({
        allUsers: [],
        users: [],
        searchText: "",
        load: false,
    });

    //redux

    const handleSearchChange = (e) => {
        let value = e.target.value
        let data = []
        state.allUsers.map((item) => {
            if(item.email.includes(value)){
                data.push(item)
            }
        })
        setState({...state, users: data, searchText: value})
    }

    const loadUsers = () => {
        //set users
        firebase.db.collection("users").onSnapshot((snapshot) => {
            const data = []
            snapshot.docs.forEach(doc => {
                data.push({
                    email: doc.data().email,
                    id: doc.id
                })
            })
            data.sort((a, b) => (a.email < b.email ? -1 : 1))
            setState({...state, allUsers: data, users: data, load: true});
        })
    }

    return (
        <div className={classes.formContainer}>
            {state.load ? (
                <>
                    <div>
                        <input className={classes.input} placeholder="Buscar por email.." value={state.searchText} onChange={(e) => {handleSearchChange(e)}}/>
                    </div>
                    {state.users.length !== 0 &&  (
                        <div>
                            {state.users.map((item, i) => (
                                <div key={i} className={classes.userContainer}>
                                    <IconButton>
                                        <PersonRounded className={classes.userIcon}/>
                                    </IconButton>
                                    <p className={classes.userText}>{item.email}</p>
                                </div>
                            ))}
                        </div>
                    )}
                </>
            ) : (
                <WhiteButton onClick={loadUsers}>Cargar usuarios</WhiteButton>
            )}
        </div>
    )
}
