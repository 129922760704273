/* 
    ter 1: mexico
    ter 2: caribe (Puerto Rico, Dominicana)
    ter 3: cenam (El Salvador, Guatemala, Honduras, Panamá, Nicaragua, Costarica)
    ter 4: region andina (Ecuador, Colombia, Perú, Regional)
    ter 5: europa-tag (Austria, Bielorussia, bulgaria, Servia, Macedonia, Croacia, Eslovenia)
    ter 6: brazil
    ter 7: cono sur (Argentina, Chile, Paraguay, Uruguay)
    ter 8: otros (Internacional, Estados Unidos, otros paises, españa)
*/

const countries = [
    {
        imgUrl: "/img/countries/bielorussia.png",
        name: "Bielorrusia",
        sin: 3,
        seg: 3,
        ter: 5,
    },
    {
        imgUrl: "/img/countries/paraguay.png",
        name: "Paraguay",
        sin: 2,
        seg: 2,
        ter: 7,
    },
    {
        imgUrl: "/img/countries/sv.png",
        name: "El Salvador",
        sin: 2,
        seg: 1,
        ter: 3,
    },
    {
        imgUrl: "/img/countries/bg.png",
        name: "Bulgaria",
        sin: 3,
        seg: 3,
        ter: 5,
    },
    {
        imgUrl: "/img/countries/pe.png",
        name: "Perú",
        sin: 2,
        seg: 2,
        ter: 4,
    },
    {
        imgUrl: "/img/countries/cl.png",
        name: "Chile",
        sin: 2,
        seg: 2,
        ter: 7,
    },
    {
        imgUrl: "/img/countries/un.png",
        name: "Otros Países",
        sin: 3,
        seg: 3,
        ter: 8,
    },
    {
        imgUrl: "/img/countries/ni.png",
        name: "Nicaragua",
        sin: 2,
        seg: 1,
        ter: 3,
    },
    {
        imgUrl: "/img/countries/mx.png",
        name: "México",
        sin: 1,
        seg: 1,
        ter: 1,
    },
    {
        imgUrl: "/img/countries/hn.png",
        name: "Honduras",
        sin: 2,
        seg: 1,
        ter: 3,
    },
    {
        imgUrl: "/img/countries/pa.png",
        name: "Panamá",
        sin: 2,
        seg: 1,
        ter: 3,
    },
    {
        imgUrl: "/img/countries/dom.png",
        name: "Dominicana",
        sin: 2,
        seg: 1,
        ter: 2,
    },
    {
        imgUrl: "/img/countries/gt.png",
        name: "Guatemala",
        sin: 2,
        seg: 1,
        ter: 3,
    },
    {
        imgUrl: "/img/countries/us.png",
        name: "Estados Unidos",
        sin: 3,
        seg: 3,
        ter: 8,
    },
    {
        imgUrl: "/img/countries/hr.png",
        name: "Croacia",
        sin: 3,
        seg: 3,
        ter: 5,
    },
    {
        imgUrl: "/img/countries/pr.png",
        name: "Puerto Rico",
        sin: 2,
        seg: 1,
        ter: 2,
    },
    {
        imgUrl: "/img/countries/mk.png",
        name: "Macedonia",
        sin: 3,
        seg: 3,
        ter: 5,
    },
    {
        imgUrl: "/img/countries/at.png",
        name: "Austria",
        sin: 3,
        seg: 3,
        ter: 5,
    },
    {
        imgUrl: "/img/countries/ar.png",
        name: "Argentina",
        sin: 2,
        seg: 2,
        ter: 7,
    },
    {
        imgUrl: "/img/countries/es.png",
        name: "España",
        sin: 3,
        seg: 3,
        ter: 8,
    },
    {
        imgUrl: "/img/countries/si.png",
        name: "Eslovenia",
        sin: 3,
        seg: 3,
        ter: 5,
    },
    {
        imgUrl: "/img/countries/uy.png",
        name: "Uruguay",
        sin: 2,
        seg: 2,
        ter: 7,
    },
    {
        imgUrl: "/img/countries/br.png",
        name: "Brasil",
        sin: 2,
        seg: 2,
        ter: 6,
    },
    {
        imgUrl: "/img/countries/cr.png",
        name: "Costa Rica",
        sin: 2,
        seg: 1,
        ter: 3,
    },
    {
        imgUrl: "/img/countries/flag.png",
        name: "Regional",
        sin: 2,
        seg: 2,
        ter: 4,
    },
    {
        imgUrl: "/img/countries/un.png",
        name: "Internacional",
        sin: 3,
        seg: 3,
        ter: 8,
    },
    {
        imgUrl: "/img/countries/ec.png",
        name: "Ecuador",
        sin: 2,
        seg: 2,
        ter: 4,
    },
    {
        imgUrl: "/img/countries/co.png",
        name: "Colombia",
        sin: 2,
        seg: 2,
        ter: 4,
    },
    {
        imgUrl: "/img/countries/sr.png",
        name: "Serbia",
        sin: 3,
        seg: 3,
        ter: 5,
    }
]

export default countries;