import React, { useState } from 'react';

//firebase
import firebase from '../../database/firebase'

//router dom
import { Link } from 'react-router-dom'

//MaterialUI
import { makeStyles } from "@material-ui/core/styles";

//utils
import { WhiteButton } from '../../utils/Buttons'

const useStyles = makeStyles((theme) =>({
    backgroundContainer: {
        minWidth: '100vw',
        minHeight: '100vh',
        backgroundImage: 'url(/./img/login/background.jpg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center'
    },
    overlay: {
        width: '100vw',
        height: '100vh',
        backgroundImage: 'linear-gradient(to bottom, rgba(20,20,20,1)0%, rgba(20,20,20,0.8)50%, rgba(20,20,20,1)100%)',
        textAlign: 'center'
    },
    logoImg: {
        width: '80px',
        position: 'relative',
        top: '20px'
    },
    formContainer: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        padding: '50px',
        width: '90%',
        maxWidth: '500px'
    },
    passwordInput: {
        flex: '1',
        width: '100%',
        padding: '15px',
        outline: 'none',
        borderRadius: '25px',
        border: 'none',
        background: "none",
        color: 'white',
        '&::placeholder': {
            color: 'rgba(255,255,255,0.75)'
        }
    },
    userInput: {
        width: '100%',
        padding: '15px',
        outline: 'none',
        borderRadius: '25px',
        border: 'none',
        marginBottom: '20px',
        background: "rgba(255,255,255,0.25)",
        color: 'white',
        '&::placeholder': {
            color: 'rgba(255,255,255,0.75)'
        }
    },
    passwordContainer: {
        display: 'flex',
        width: '100%',
        background: 'rgba(255,255,255,0.25)',
        borderRadius: '25px',
        border: 'none',
        marginBottom: '20px',
    },
    errorContainer: {
        background: 'rgba(255,255,255,0.25)',
        borderRadius: 10,
        padding: 20,
        marginTop: 20
    },
    errorText: {
        color: 'white',
        fontSize: '25px'
    },
    link: {
        color: 'white',
        '&:hover': {
            color: 'white'
        }
    }
}))

export default function ForgotPassword({classes}) {
    classes = useStyles();

    //State
    const [values, setValues] = useState({
        email: '',
        error: "",
        success: "",
    });


    const sendMail = () => {
        firebase.firebase.auth().sendPasswordResetEmail(values.email).then(function() {
            setValues({...values, success: "El correo se ha enviado", error: ""})
        }).catch(function(error) {
            setValues({...values, success: "", error: error.message})
        });

    }

    return (
        <div className={classes.backgroundContainer}>
            <div className={classes.overlay}>
                <img alt="TerniumLogo" className={classes.logoImg} src="/./img/logo/logo.png"/>
                <div className={classes.formContainer}>
                    <div style={{display: 'flex'}}>
                        <input 
                            type="text" 
                            placeholder="Email"
                            value={values.email}
                            onChange={(e) => setValues({...values, email: e.target.value})}
                            className={classes.userInput}
                        />
                    </div>
                    <div>
                        <WhiteButton onClick={sendMail}>Enviar correo</WhiteButton>
                        <Link to="/" className={classes.link}>Regresa a iniciar sesión</Link>
                    </div>
                    {values.error !== "" && (
                        <div className={classes.errorContainer}>
                            <h2 className={classes.errorText}>{values.error}</h2>
                        </div>
                    )}
                    {values.success !== "" && (
                        <div className={classes.errorContainer}>
                            <h2 className={classes.errorText}>{values.success}</h2>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}
