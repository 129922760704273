const sources = [
    'El Economista', 
    'El CEO', 
    'Milenio',
    'Meganoticias',
    'MSN', 
    'El Heraldo de México', 
    'El Porvenir', 
    'Expansión', 
    'Xataka', 
    'Tecnoempresa', 
    'Marcomares', 
    'Infobae', 
    'Merca 2.0', 
    'Business Insider', 
    'Marketers', 
    'Pasion Móvil', 
    'e-Consulta', 
    'Hoja de Ruta Digital', 
    'El Sol de México', 
    'Excelsior', 
    'Centro Urbano', 
    'Isopixel', 
    'RC Noticias', 
    'Listín Diario', 
    'El Nuevo Diario', 
    'El Nuevo Día', 
    'Telemundo', 
    'Microsoft', 
    'Diario 1', 
    'Prensa Libre', 
    'La Hora', 
    'CawTV', 
    'La Verdad Panamá', 
    'Swissinfo', 
    'Metro Libre', 
    'Crítica', 
    'Telemetro', 
    'La República', 
    'Valora Analitik', 
    'Dirigentes', 
    'BBVA', 
    '360 Radio', 
    'Semana', 
    'El Tiempo', 
    '790 AM', 
    'SEMANA', 
    'Caracol', 
    'BN Americas', 
    'Portafolio', 
    'Teleamazonas', 
    'Halcones y Palomas', 
    'DPL News', 
    'Ámbito', 
    'Andina', 
    'El Comercio', 
    'Perú 21', 
    'TV y Video', 
    'El Peruano', 
    'RPP', 
    'Eco TV Panamá', 
    'Techcrunch', 
    'TodoTV News', 
    'Prensario', 
    'Mobile World Live', 
    'Mobile Bulgaria', 
    'Yettel', 
    'Actual No', 
    'See News', 
    'Nacional', 
    'Total Croatia News', 
    'Telekom Slovenije', 
    'Broadband TV News', 
    'Global Legal Chronicle', 
    'Total Telecom', 
    'Beta', 
    'Tele Síntese', 
    'Teletime', 
    'Tele  Síntese', 
    'Tecnoblog', 
    'Minha Operadora', 
    'Notimérica', 
    'América Economía', 
    'Convergencia Digital', 
    'Mobile Time', 
    'El Diario Carlos Paz', 
    'Diario Jornada', 
    'Telesemana', 
    'Digital Policy', 
    'Telam Digital', 
    'Diario Estrategia', 
    'El Día', 
    'El Periodista', 
    'Pisapapeles', 
    'ABC', 
    'La Nación', 
    'Investing', 
    'Cinco Días', 
    'Telefónica', 
    'Estrategias de Inversión', 
    'OK Diario',
    "La Información",
    "Light Reading",
    "La Vanguardia",
    "Hipertextual",
    "Sentido Común",
    "Fierce Wireless",
    "IT Espresso",
    "Telecompetitor",
    "Fierce Video",
    "Bloomberg",
    "CNN",
    "AT&T",
    "Telecompaper",
    "El Financiero",
    "Bloomberg Línea",
    "TV Azteca",
    "La Jornada",
    "20 Minutos",
    "Consenso del Mercado",
    "Muy Computer Pro",
    "Capital Madrid",
    "Globe Newswire",
    "PR Newswire",
    "Deutsche Telekom",
    "Uno Cero",
    "Telecoms",
    "Data Center Dynamics",
    "Deutsche Welle",
    "Reuters",
    "Europa Press",
    "Wall Street Journal",
    "Trece Bits",
    "Periódico Digital",
    "El País",
    "El País Financiero",
    "IP Paraguay",
    "Samsung",
]

export default sources