import React, { useEffect, useState } from 'react'

//Firebase
import firebase from '../../database/firebase'

//Link
import { Link, useLocation, useHistory } from "react-router-dom";

//social share
import {
    EmailShareButton,
    FacebookShareButton,
    WhatsappShareButton,
    TwitterShareButton,
    FacebookIcon,
    TwitterIcon,
    WhatsappIcon,
    EmailIcon,
} from "react-share";

//MaterialUI
import { makeStyles  } from "@material-ui/core/styles";
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import ShareRoundedIcon from '@material-ui/icons/ShareRounded';
import Backdrop from '@material-ui/core/Backdrop';
import Snackbar from '@material-ui/core/Snackbar';
import { IconButton } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { CloseRounded, FileCopyRounded } from '@material-ui/icons';

//Boostrap
import { Row, Col } from 'react-bootstrap';

//redux
import { useSelector } from 'react-redux';

//loader
import Loader from "react-loader-spinner";

//Constants
import countries from '../../constants/countries'

//Components
import { WhiteButton } from '../../utils/Buttons'

//pull to refresh
import {PullToRefresh} from "react-js-pull-to-refresh";
import {PullDownContent, ReleaseContent, RefreshContent} from "react-js-pull-to-refresh";

const useStyles = makeStyles((theme) =>({
    root: {
        width: '100%',
        '& > * + *': {
        marginTop: theme.spacing(2),
        },
    },
    firstNewContainer: {
        paddingTop: 0,
        width: "100vw",
        minHeight: "calc(100vh - 70px)",
        //backgroundImage: `url(./img/news/img${Math.floor(Math.random()*25)+1}.jpg)`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        [theme.breakpoints.down('576')]: {
            display: 'none'
        }
    },
    firstNewOverlay: {
        width: "100%",
        minHeight: "calc(100vh - 70px)",
        backgroundImage: 'linear-gradient(to bottom, rgba(20,20,20,1)5%, rgba(20,20,20,0.50)50%, rgba(20,20,20,1)75%)',
        padding: '230px 10px 50px 10px',
        [theme.breakpoints.down('576')]: {
            padding: '100px 10px 50px 10px',
        }
    },
    firstNewInnerContainer: {
        background: 'none',
        color: 'white !important',
        width: "95%",
        margin: 'auto',
        maxWidth: 900
    },
    firstNewCountryContainer: {
        display: "flex",
        alignItems: "center",
        justifyItems: "center"
    },
    flag: {
        width: "40px",
        height: "40px",
        borderRadius: "50px",
        backgroundSize: "auto",
        backgroundPosition: "center",
        marginRight: "20px",
        overflow: "hidden"
    },
    firstNewCountry: {
        marginBottom: 0
    },
    firstNewTitle: {
        fontSize: "45px",
        fontWeight: 'bold',
        marginTop: 30,
        marginBottom: 30,
        [theme.breakpoints.down('576')]: {
            fontSize: 25
        }
    },
    firstNewInfoContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    icons: {
        color: 'white',
        transition: 'transform 450ms',
        fontSize: '30px',
        '&:hover': {
            transform: 'scale(1.2)'
        }
    },
    firstNewSource: {
        marginBottom: 5,
        fontWeight: "bold",
        fontSize: "20px"
    },
    firstNewDate: {
        marginBottom: 0,
        fontStyle: 'italic'
    },
    errorContainer: {
        background: 'none',
        color: 'white !important',
        width: "95%",
        margin: 'auto',
        maxWidth: 600
    },
    errorContainer2: {
        background: 'none',
        color: 'white !important',
        width: "90%",
        margin: 'auto',
        maxWidth: 600,
        position: 'relative',
        //top: '20vh',
        [theme.breakpoints.up('576')]: {
            display: 'none'
        }
    },
    errorMessage: {
        fontSize: '35px',
        fontWeight: 'bold',
        [theme.breakpoints.down('576')]: {
            fontSize: '25px'
        }
    },
    newsOuterContainer: {
        background: 'rgb(20,20,20)',
        minHeight: 'calc(100vh - 70px)',
        [theme.breakpoints.up('576')]: {
            marginTop: '-10vh',
        },
        [theme.breakpoints.down('576')]:{
            paddingTop: '20px'
        }
    },
    newCol: {
        [theme.breakpoints.down('576')]: {
            padding: 0
        }
    },
    newContainer: {
        width: '90%',
        maxWidth: '300px',
        margin: 'auto',
        minHeight: '400px',
        backgroundSize: "cover",
        backgroundPosition: "center",
        borderRadius: '10px',
        marginBottom: 30,
        [theme.breakpoints.down('576')]: {
            minHeight: '200px',
            width: '100%',
            maxWidth: '400px',
            marginBottom: 0,
            borderRadius: '0px',
            borderBottom: '1px solid lightgray'
        },
        transition:  'transform 450ms',
        '&:hover': {
            transform: 'scale(1.02)'
        }
    },
    newOverlay: {
        width: "100%",
        height: "100%",
        minHeight: '400px',
        background: 'rgba(20,20,20,0.75)',
        padding: '10px',
        [theme.breakpoints.down('576')]: {
            minHeight: '200px'
        }
    },
    newInnerContainer: {
        minHeight: '380px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        [theme.breakpoints.down('576')]: {
            minHeight: '200px'
        }
    },
    newsContainer: {
        color: 'white !important',
        width: "95%",
        margin: 'auto',
        maxWidth: 900,
        [theme.breakpoints.down('576')]: {
            width: '100%',
        }
    },
    newFlag: {
        width: "30px",
        height: "30px",
        borderRadius: "50px",
        backgroundSize: "auto",
        backgroundPosition: "center",
        marginRight: "20px",
        overflow: "hidden"
    },
    newTitle: {
        fontSize: '20px',
        fontWeight: 'bold',
    },
    newLink: {
        color: 'white !important',
        '&:hover': {
            textDecoration: 'none',
        }
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    shareContainer: {
        background: 'rgb(20,20,20)',
        padding: 20,
        borderRadius: 10,
        width: '90%',
        maxWidth: '800px'
    },
    shareHeaderContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    shareCloseIcon: {
        color: 'white',
        fontSize: 35,
        transition: 'transform 450ms',
        '&:hover': {
            transform: 'scale(1.2)'
        }
    },
    shareIconContainer: {
        display: 'flex',
        justifyContent: 'space-evenly',
        margin: 'auto',
        maxWidth: 400,
        width: '95%'
    },
    loaderOuterContainer:  {
        width: '100%',
        height: 'calc(100vh - 70px)',
        background: 'rgb(20,20,20)',
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center'
    },
    loaderContainer:  {
        margin: 'auto',
        width: 80,
        position: 'relative',
        top: '-35px'
    },
    messageContainer: {
        width: '90%',
        maxWidth: '400px',
        margin: 'auto',
        position: 'relative',
        color: 'white',
        textAlign: 'center',
        paddingBottom: '40px',
        [theme.breakpoints.down('576')]:  {
            top: '0px',
            paddingTop: '30px'
        }
    }
}))

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function shuffle(array) {
    var currentIndex = array.length, temporaryValue, randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {

        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;

        // And swap it with the current element.
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
    }

    return array;
}

let numbers =  shuffle(Array.from({length: 169}, (_, index) => index + 1))

export default function News(props, {classes}) {
    classes = useStyles()
    const [state, setState] = useState({
        news: [] || JSON.parse(localStorage.getItem("news")),
        countries: [],
        open: false,
        snackOpen: false,
        shareNewId: "",
        loading: true,
        searching: false
    })

    //redux
    const user = useSelector(state => state.userState.user);

    //router
    const search = useLocation().search
    const history = useHistory()
    const country = new URLSearchParams(search).get('country')
    const keyword = new URLSearchParams(search).get('keyword')
    const date1 = new URLSearchParams(search).get('date1')
    const date2 = new URLSearchParams(search).get('date2')

    const getCountryImage = (country) => {
        let countryImage = ""
        countries.map((item) => {
            if(item.name === country){
                countryImage = item.imgUrl
            }
        })
        return countryImage
    }

    const isSearch = () => {
        if(country !== null){
            return true;
        }
        if(keyword !== null){
            return true;
        }
        if(date1 !== null){
            return true;
        }
        if(date2 !== null){
            return true;
        }
        return false;
    }

    const validateNew = (data, id) => {
        let flag = true;
        data.forEach((item) => {
            if(item.id === id) {
                flag = false;
            }
        })
        return flag
    }
    
    const loadSearchWithKeyword = (data, keywords) => {
        firebase.db.collection("news")
            .where("date", "<=", date2)
            .where("date", ">=", date1)
            .orderBy("date", "desc")
            .where("keywords", "array-contains-any", keywords)
            .limit(50)
            .onSnapshot(snapshot => {
                snapshot.docs.forEach((item) => {
                    if(validateNew(data, item.id)) {
                        data.push({
                            id: item.id,
                            country: item.data().country,
                            date: item.data().date,
                            destacada: item.data().destacada,
                            newType: item.data().newType,
                            pdf: item.data().pdf,
                            resumen: item.data().resumen,
                            source: item.data().source,
                            title: item.data().title,
                            keywords: item.data().keywords,
                            url: item.data().url,
                            countryImage: getCountryImage(item.data().country, data)
                        })
                    }
                })
                setState({...state, news: data, loading: false, searching: true})
            })

    }

    const loadSearchWithCountry = (data) => {
        firebase.db.collection("news")
            .where("country", "==", country)
            .where("date", "<=", date2)
            .where("date", ">=", date1)
            .orderBy("date", "desc")
            .limit(50)
            .onSnapshot(snapshot => {
                snapshot.docs.forEach((item) => {
                    if(validateNew(data, item.id)) {
                        data.push({
                            id: item.id,
                            country: item.data().country,
                            date: item.data().date,
                            destacada: item.data().destacada,
                            newType: item.data().newType,
                            pdf: item.data().pdf,
                            resumen: item.data().resumen,
                            source: item.data().source,
                            title: item.data().title,
                            keywords: item.data().keywords,
                            url: item.data().url,
                            countryImage: getCountryImage(item.data().country, data)
                        })
                    }
                })
                setState({...state, news: data, loading: false, searching: true})
            })
    }

    const loadSearchWithKeywordCountry = (data, keywords) => {
        firebase.db.collection("news")
            .where("country", "==", country)
            .where("keywords", "array-contains-any", keywords)
            .where("date", ">=", date1)
            .where("date", "<=", date2)
            .orderBy("date", "desc")
            .limit(50)
            .onSnapshot(snapshot => {
                snapshot.docs.forEach((item) => {
                    data.push({
                        id: item.id,
                        country: item.data().country,
                        date: item.data().date,
                        destacada: item.data().destacada,
                        newType: item.data().newType,
                        pdf: item.data().pdf,
                        resumen: item.data().resumen,
                        source: item.data().source,
                        title: item.data().title,
                        keywords: item.data().keywords,
                        url: item.data().url,
                        countryImage: getCountryImage(item.data().country, data)
                    })
                })
                setState({...state, news: data, loading: false, searching: true})
            })
    }

    const loadSearch = (data) => {
        firebase.db.collection("news")
            .where("date", ">=", date1)
            .where("date", "<=", date2)
            .orderBy("date", "desc")
            .limit(50)
            .onSnapshot(snapshot => {
                snapshot.docs.forEach((item) => {
                    if(validateNew(data, item.id)) {
                        data.push({
                            id: item.id,
                            country: item.data().country,
                            date: item.data().date,
                            destacada: item.data().destacada,
                            newType: item.data().newType,
                            pdf: item.data().pdf,
                            resumen: item.data().resumen,
                            source: item.data().source,
                            title: item.data().title,
                            keywords: item.data().keywords,
                            url: item.data().url,
                            countryImage: getCountryImage(item.data().country, data)
                        })
                    }
                })
                setState({...state, news: data, loading: false, searching: true})
            })
    }

    //load news when on search
    const loadNewsSearch = () => {
        let data = []
        if(keyword !== null  && keyword !== ""){
            let key = keyword.replaceAll("and", "&")
            let keywords = key.split("_")

            //check if country is set
            if(country === ""){
                loadSearchWithKeyword(data, keywords)
            } else{
                loadSearchWithKeywordCountry(data, keywords)
            }
        } else{
            //check if country is set
            if(country !== ""){
                loadSearchWithCountry(data)
                
            } else{
                loadSearch(data)
            }
        }
    }


    //load news when normal
    const loadNews = () => {
        let today = new Date()
        let todayDate = today.toString()

        let currentDate = todayDate.substring(0,15) // current date
        let lastDate = localStorage.getItem("date") // last date in local Storage

        // check if current date is different from last date
        //if is different it must fetch data if it is not weeken
        if(currentDate !== lastDate || JSON.parse(localStorage.getItem("news")) === null || localStorage.getItem("countriesChange") === "1"){
            //new day
            refreshNews()
        }  else{
            if(localStorage.getItem("newsStatus") !== null){
                if(!localStorage.getItem("newsStatus")){
                    refreshNews(); //loaded news in current date but has not loaded total news
                } else {
                    setState({...state, news: JSON.parse(localStorage.getItem("news"))}) //has loaded total news
                }
            } else {
                setState({...state, news: JSON.parse(localStorage.getItem("news"))}) //has loaded total news
            }
            
        }
        localStorage.setItem("newsStatus", true)
        localStorage.setItem("countriesChange", 0)
    }

    const refreshNews = () => {
        let today = new Date()
        let todayDate = today.toString()
        let weekDay = todayDate.substring(0,3) // current weekday


        if(weekDay === "Sat"){
            today.setDate(today.getDate() - 1)
        } else if (weekDay === "Sun"){
            today.setDate(today.getDate() - 2)
        }
        
        let year = today.getFullYear()
        let month = parseInt(today.getMonth())+1;
        let day = today.getDate()
        let date = year + "-" + (month < 10 ? "0" : "") + month + "-" + (day < 10 ? "0" : "")  + day

        todayDate = today.toString()
        let currentDate = todayDate.substring(0,15) // current date

        firebase.db.collection("news")
                .where("date", "==", date)
                .onSnapshot(snapshot => {
                    let items = []
                    snapshot.docs.forEach((item) => {
                        if(user.excludedCountries.indexOf(item.data().country) === -1){
                            let info = {
                                id: item.id,
                                country: item.data().country,
                                date: item.data().date,
                                destacada: item.data().destacada,
                                newType: item.data().newType,
                                pdf: item.data().pdf,
                                resumen: item.data().resumen,
                                source: item.data().source,
                                title: item.data().title,
                                keywords: item.data().keywords,
                                url: item.data().url,
                                countryImage: getCountryImage(item.data().country)
                            }
                            items.push(info)
                        }
                        
                    })
                    if(items.length < 30){
                        firebase.db.collection("news").where("date", "<", date).orderBy("date", "desc").limit(30-items.length).onSnapshot(snap => {
                            snap.docs.forEach((item) => {
                                if(user.excludedCountries.indexOf(item.data().country) === -1){
                                    let info = {
                                        id: item.id,
                                        country: item.data().country,
                                        date: item.data().date,
                                        destacada: item.data().destacada,
                                        newType: item.data().newType,
                                        pdf: item.data().pdf,
                                        resumen: item.data().resumen,
                                        source: item.data().source,
                                        title: item.data().title,
                                        url: item.data().url,
                                        keywords: item.data().keywords,
                                        countryImage: getCountryImage(item.data().country)
                                    }
                                    items.push(info)
                                }
                            })
                            items.sort((a, b) => (b.destacada < a.destacada ? -1 : 1))
                            items.sort((a, b) => (b.date < a.date ? -1 : 1))
                            localStorage.setItem("news", JSON.stringify(items))
                            setState({...state, news: items, loading: false})
                        })
                    } else{
                        items.sort((a, b) => (b.destacada < a.destacada ? -1 : 1))
                        items.sort((a, b) => (b.date < a.date ? -1 : 1))
                        localStorage.setItem("news", JSON.stringify(items))
                        setState({...state, news: items, loading: false})
                    }
                }) 
                localStorage.setItem("date", currentDate)
        return
    }

    useEffect(() => {
        //console.log(country, date1, date2, keyword)
        if(isSearch()){
            loadNewsSearch()
        } else{
            firebase.db.collection("status").doc('QYeviLwq5wrtgDnZhjVU').onSnapshot(item => {
                if(item.data().status){
                    loadNews();
                } else{
                    localStorage.setItem("newsStatus", false)
                    refreshNews()
                }
            })
        }
    },[country, date1, date2, keyword])

    const getFormattedDate = (date) => {
        let months = [
            "enero",
            "febrero",
            "marzo",
            "abril",
            "mayo",
            "junio",
            "julio",
            "agosto",
            "septiembre",
            "octubre",
            "noviembre",
            "diciembre"
        ]
        return `${parseInt(date.substring(8,10))} de ${months[parseInt(date.substring(5,7))-1]} de ${date.substring(0,4)}`
    }

    const handleShareChange = (id,e) => {
        e.preventDefault();
        e.stopPropagation();
        setState({...state, open: !state.open, shareNewId: id})
    }

    const handleSnackChange = () => {
        setState({...state, snackOpen: !state.snackOpen})
    }

    const hanldeNewClick = (e, item) => {
        e.preventDefault()
        
        localStorage.setItem("newItemId", item.id)
        localStorage.setItem("newItem", JSON.stringify(item));
        (state.searching ? history.push(`noticia?id=${item.id}&country=${country || ""}&keyword=${keyword || ""}&date1=${date1 || "2019-02-21"}&date2=${date2 || ""}`) : history.push(`/noticia?id=${item.id}`));
    }

    const handleSearchAgain = (e) => {
        e.preventDefault()
        history.push(`/buscar?country=${country}&keyword=${keyword}&date1=${date1}&date2=${date2}`)
    }

    const onRefresh = () => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve(refreshNews());
            }, 1000);
        })
    }

    return (
        <PullToRefresh
            pullDownContent={<PullDownContent />}
            releaseContent={<ReleaseContent />}
            refreshContent={<RefreshContent />}
            pullDownThreshold={100}
            onRefresh={onRefresh}
            triggerHeight={50}
            backgroundColor='rgb(20,20,20)'
            startInvisible={true}
        >

            {/* First new */}
            <div className={classes.firstNewContainer} style={{backgroundImage: `url(${process.env.PUBLIC_URL}/img/news/img${numbers[0]}.jpg)`}}>
                <div className={classes.firstNewOverlay}>
                    {state.news.length !== 0 ? (
                        <div className={classes.firstNewInnerContainer}>
                            <div className={classes.firstNewCountryContainer}>
                                <div className={classes.flag} style={{backgroundImage: `url(${process.env.PUBLIC_URL}${state.news[0].countryImage})`, backgroundSize: state.news[0].country === "Dominicana" ? "cover" : "auto"}}></div>
                                <p className={classes.firstNewCountry}>{state.news[0].country}</p>
                            </div>
                            <h1 className={classes.firstNewTitle}>{state.news[0].title}</h1>
                            <div className={classes.firstNewInfoContainer}>
                                <div>
                                    <p className={classes.firstNewSource}>{state.news[0].source}</p>
                                    <p className={classes.firstNewDate}>{getFormattedDate(state.news[0].date)}</p>
                                </div>
                                <div>
                                    <Link to={`/noticia?id=${state.news[0].id}`}>
                                        <IconButton>
                                            <InfoRoundedIcon className={classes.icons}/>
                                        </IconButton>
                                    </Link>
                                    
                                    <IconButton onClick={(e) => {handleShareChange(state.news[0].url, e)}}>
                                        <ShareRoundedIcon className={classes.icons}/>
                                    </IconButton>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className={classes.errorContainer}>
                            {state.loading ? (
                                /* loading */
                                <div className={classes.loaderContainer}>
                                    <Loader  type="BallTriangle" color="#FFFFFF" height={80} width={80} />
                                </div>
                            ) : (
                                /* No results */
                                <div>
                                    <h2 className={classes.errorMessage}>Lo sentimos... no hay resultados para su búsqueda</h2>
                                    <WhiteButton onClick={(e) => {handleSearchAgain(e)}}>Volver a buscar</WhiteButton>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>

            {/* News */}
            <div className={classes.newsOuterContainer}>
                {state.news.length !== 0 ? (
                    <Row className={classes.newsContainer}>
                        {state.news  && state.news.map((item, i) => (
                            <Col sm={6} md={4} key={i} className={classes.newCol}>
                                <Link to={`/noticia?id=${item.id}`} className={classes.newLink} onClick={e => {hanldeNewClick(e, item)}}>
                                    <div className={classes.newContainer} style={{backgroundImage: `url(${process.env.PUBLIC_URL}/img/news/img${numbers[i+1] % 169}.jpg)`}}>
                                        <div className={classes.newOverlay}>
                                            <div className={classes.newInnerContainer}>
                                                <div className={classes.firstNewCountryContainer}>
                                                    <div className={classes.newFlag} style={{backgroundImage: `url(${process.env.PUBLIC_URL}${item.countryImage})`, backgroundSize: item.country === "Dominicana" ? "cover" : "auto"}}></div>
                                                    <p className={classes.firstNewCountry}>{item.country}</p>
                                                </div>
                                                <h1 className={classes.newTitle}>{item.title}</h1>
                                                <div className={classes.firstNewInfoContainer}>
                                                    <div style={{flex: 1}}>
                                                    <p className={classes.firstNewSource}>{item.source}</p>
                                                    <p className={classes.firstNewDate}>{getFormattedDate(item.date)}</p>
                                                </div>
                                                <div className={classes.newIconContainer}>
                                                    <IconButton onClick={(e) => {handleShareChange(item.id, e)}}>
                                                        <ShareRoundedIcon className={classes.icons}/>
                                                    </IconButton>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </Col>
                    ))}
                    </Row>
                ) : (
                    <div className={classes.errorContainer2}>
                        {state.loading ? (
                            /* loading */
                            <div className={classes.loaderContainer}>
                                <Loader  type="BallTriangle" color="#FFFFFF" height={80} width={80} />
                            </div>
                        ) : (
                            /* No results */
                            <div>
                                <h2 className={classes.errorMessage}>Lo sentimos... no hay resultados para su búsqueda</h2>
                                <WhiteButton onClick={(e) => {handleSearchAgain(e)}}>Volver a buscar</WhiteButton>
                            </div>
                        )}
                    </div>
                )}
                

                {(state.searching && state.news.length >= 50) && (
                    <div className={classes.messageContainer}>
                        <h4>Se ha alcanzado el límite de noticias</h4>
                        <WhiteButton onClick={(e) => {handleSearchAgain(e)}}>Volver a buscar</WhiteButton>
                    </div>
                )}

                {/* Social modal */}
                <Backdrop className={classes.backdrop} open={state.open} onClick={(e) => handleShareChange(state.shareNewId,e)}>
                    <div className={classes.shareContainer} onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                    }}>
                        <div className={classes.shareHeaderContainer}>
                            <h3>Comparta la noticia</h3>
                            <IconButton onClick={(e) => handleShareChange(state.shareNewId,e)}>
                                <CloseRounded className={classes.shareCloseIcon}/>
                            </IconButton>
                        </div>
                        <div className={classes.shareIconContainer}>
                            <FacebookShareButton
                                url={`${window.location.origin.toString()}/noticia?id=${state.shareNewId}`}
                                quote={"title"}
                                className="Demo__some-network__share-button"
                            >
                                <FacebookIcon size={40} round />
                            </FacebookShareButton>
                            <WhatsappShareButton
                                url={`${window.location.origin.toString()}/noticia?id=${state.shareNewId}`}
                                quote={"title"}
                                className="Demo__some-network__share-button"
                            >
                                <WhatsappIcon size={40} round />
                            </WhatsappShareButton>
                            <TwitterShareButton
                                url={`${window.location.origin.toString()}/noticia?id=${state.shareNewId}`}
                                quote={"title"}
                                className="Demo__some-network__share-button"
                            >
                                <TwitterIcon size={40} round />
                            </TwitterShareButton>
                            <EmailShareButton
                                url={`${window.location.origin.toString()}/noticia?id=${state.shareNewId}`}
                                quote={"title"}
                                className="Demo__some-network__share-button"
                            >
                                <EmailIcon size={40} round />
                            </EmailShareButton>
                            <IconButton onClick={() => {
                                navigator.clipboard.writeText(`${window.location.origin.toString()}/noticia?id=${state.shareNewId}`);
                                handleSnackChange()
                            }} style={{background: 'gray', padding: 10}}>
                                <FileCopyRounded style={{fontSize: '20px', color: 'white'}}/>
                            </IconButton>
                        </div>
                    </div>
                    <Snackbar open={state.snackOpen} autoHideDuration={6000} onClose={handleSnackChange}>
                        <Alert onClose={handleSnackChange} severity="success">
                        Se copió el link exitosamente!
                        </Alert>
                    </Snackbar>
                </Backdrop>
            </div>
        </PullToRefresh>
    )
}
