import React from 'react';

//Router dom
import { Link } from "react-router-dom";

//Material UI
import { makeStyles } from "@material-ui/core/styles";
import HomeRoundedIcon from '@material-ui/icons/HomeRounded';
import DescriptionRoundedIcon from '@material-ui/icons/DescriptionRounded';
import ViewHeadlineRoundedIcon from '@material-ui/icons/ViewHeadlineRounded';
import PersonAddRoundedIcon from '@material-ui/icons/PersonAddRounded';
import DataUsageRoundedIcon from '@material-ui/icons/DataUsageRounded';

const useStyles = makeStyles((theme) => ({
    backgroundContainer: {
        position: 'relative',
        width: '100vw',
        maxWidth: '100vw',
        height: '100px',
        paddingTop: '20px',
        paddingBottom: '20px',
        textAlign: 'center',
        marginBottom: '30px'
    },
    itemsContainer: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        backgroundColor: 'rgba(250,250,250,0.25)',
        width: '90%',
        maxWidth: '600px',
        margin: 'auto',
        borderRadius:  '15px',
        padding: '10px'
    },
    itemLink: {
        color: "white",
        '&:hover': {
            color:  "white",
            textDecoration: 'none'
        }
    },
    item: {
        padding: '5px',
        '&:hover': {
            backgroundColor: 'rgba(250,250,250,0.15)',
            borderRadius: '5px',
        }
    },
    itemLabel: {
        marginBottom: '0px',
    }
}));

export default function Index({classes}) {
    classes = useStyles();

    return (
        <div className={classes.backgroundContainer}>
            <div className={classes.itemsContainer}>
                <Link className={classes.itemLink}  to="/admin">
                    <div className={classes.item}>
                        <HomeRoundedIcon className={classes.itemIcon}/>
                        <p className={classes.itemLabel}>Inicio</p>
                    </div>
                </Link>
                <Link className={classes.itemLink}  to="/admin/noticias">
                    <div className={classes.item}>
                        <DescriptionRoundedIcon className={classes.itemIcon}/>
                        <p className={classes.itemLabel}>Noticias</p>
                    </div>
                </Link>
                <Link className={classes.itemLink}  to="/admin/resumen">
                    <div className={classes.item}>
                        <DataUsageRoundedIcon className={classes.itemIcon}/>
                        <p className={classes.itemLabel}>Resumen</p>
                    </div>
                </Link>
                <Link className={classes.itemLink}  to="/admin/newsletter">
                    <div className={classes.item}>
                        <ViewHeadlineRoundedIcon className={classes.itemIcon}/>
                        <p className={classes.itemLabel}>Newsletter</p>
                    </div>
                </Link>
                <Link className={classes.itemLink}  to="/admin/edit-users">
                    <div className={classes.item}>
                        <PersonAddRoundedIcon className={classes.itemIcon}/>
                        <p className={classes.itemLabel}>Usuarios</p>
                    </div>
                </Link>
            </div>
        </div>
    )
}