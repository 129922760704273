import React, { useState, useEffect } from 'react'

//firebase
import firebase from '../../database/firebase'

//Components
import Header from './Header'
import { TransparentInput } from '../../utils/Select'
import { WhiteRadio } from '../../utils/RadioButtons';
import { BlackButton, WhiteButton } from '../../utils/Buttons'

//MaterialUI
import { makeStyles } from "@material-ui/core/styles";
import { IconButton } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Collapse from '@material-ui/core/Collapse';
import CreateRoundedIcon from '@material-ui/icons/CreateRounded';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
//import LinearProgress from '@material-ui/core/LinearProgress';

//redux
import { useSelector } from 'react-redux';

//Router dome
import { Redirect } from "react-router-dom";

//bootstrap
import { Col, Row } from 'react-bootstrap';

//Constants
import countries from '../../constants/countries'
import sources from '../../constants/sources'
import companies from '../../constants/companies'

const useStyles = makeStyles((theme) =>({
    backgroundContainer: {
        backgroundImage: 'url(/./img/login/background.jpg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        overflow: "hidden",
        [theme.breakpoints.up('1200')]: {
            backgroundAttachment: 'fixed'
        }
    },
    overlay: {
        backgroundImage: 'linear-gradient(to bottom, rgba(20,20,20,1)0%, rgba(20,20,20,0.9)50%, rgba(20,20,20,1)100%)',
        paddingBottom: "50px",
    },
    title: {
        color: "white",
        textAlign: "center",
        fontWeight: "bold",
        marginBottom: "50px",
        [theme.breakpoints.down('576')]: {
            fontSize: 30
        }
    },
    formContainer: {
        width: "95%",
        maxWidth: "720px",
        margin: "auto",
        background: "rgba(255, 255, 255,0.15)",
        padding: "20px",
        borderRadius: "10px",
        paddingTop: "35px",
        marginBottom: "30px"
    },
    formControl: {
        width: "100%"
    },
    selectLabel: {
        color: "white !important",
        fontSize: "18px !important",
        fontWeight: "bold"
    },
    input: {
        width: '100%',
        padding: '15px',
        outline: 'none',
        borderRadius: '25px',
        border: 'none',
        marginBottom: '30px',
        color: 'white',
        background: "rgba(255,255,255,0.25)",
        '&::placeholder': {
            color: 'rgba(255,255,255,0.75)'
        }
    },
    errorContainer: {
        background: "rgba(255, 255, 255,0.15)",
        borderRadius: "10px",
        padding: 20,
        color: 'white',
        position: 'relative',
        fontWeight: 'bold',
        marginTop: 20
    },
    newsLengthContainer: {
        padding: 20
    },
    newsLength: {
        color: 'white',
        textAlign: 'center',
        fontWeight: 'bold'
    },
    btnsContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    btn: {
        flex: 0.4
    },


    //news editor
    newsContainer: {
        width: "95%",
        margin: "auto",
        background: "rgba(255, 255, 255,0.15)",
        padding: "20px",
        borderRadius: "10px",
        paddingTop: "35px",
        marginBottom: "30px",
    },
    subtitle: {
        color: 'white',
        textAlign: 'center',
        marginBottom: 30
    },
    newContainer: {
        background: "rgba(255, 255, 255,0.05)",
        padding: "10px",
        borderRadius: "10px",
        marginBottom: 20
    },
    newItemContainer: {
        display: "flex",
        alignItems: "center",
        overflowX: 'scroll',
        justifyContent: 'space-between',
    },
    countryContainer: {
        display: "flex",
        alignItems: "center",
    },
    flag: {
        width: "40px",
        height: "30px",
        borderRadius: "5px",
        backgroundSize: "cover",
        backgroundPosition: "center",
        marginRight: "10px"
    },
    flagName: {
        flex: "1",
        color: "white",
        fontWeight: "bold",
        marginBottom: 0
    },
    itemContainer: {
        marginLeft: '20px',
        minWidth: 150
    },
    itemContainer2: {
        marginLeft: '20px',
        minWidth: 30
    },
    itemText: {
        color: 'white',
        marginBottom: 0
    },
    icon: {
        fontSize: "30px", 
        color: "white",
        transition: 'transform 450ms',
        '&:hover':{
            transform: 'scale(1.2)'
        }
    },
    formContainer2: {
        width: "100%",
        maxWidth: "600px",
        margin: "auto",
        padding: "20px",
        borderRadius: "10px",
        paddingTop: "35px",
        marginBottom: "30px"
    },
    pdfContainer: {
        paddingBottom: 40
    },
    pdfInnerContainer: {
        display: 'flex',
        alignItems: 'center',
        color: 'white',
        marginBottom: 10
    },
    pdfText: {
        marginBottom: 0,
    }
}))

export default function Noticias({classes}) {
    const getDate = () => {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();
        if(today.getHours() > 16){
            today.setDate(today.getDate() + 1)
            dd = String(today.getDate()).padStart(2, '0');
            mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            yyyy = today.getFullYear()
        }
        today = yyyy + '-' + mm + '-' + dd;
        return today;
    }

    //state
    const [state, setState] = useState({
        country: localStorage.getItem("country") || "",
        countries: [],
        newType: localStorage.getItem("newType") || "Seguimiento a la Competencia",
        destacada: localStorage.getItem("destacada") || "No",
        source: localStorage.getItem("source") || "",
        url: localStorage.getItem("url") || "",
        sector: localStorage.getItem("sector") || "",
        company: localStorage.getItem("company") || "",
        pdf: null,
        pdfText: "",
        resumen: localStorage.getItem("resumen") || "",
        title: localStorage.getItem("title") || "",
        date: getDate(),
        error: "",
        success: "",
        news: JSON.parse(localStorage.getItem("addedNews")) || [],
        sintesis: 0,
        seguimiento: 0,
        tempSources: []
    })
    const [newsAdded, setNewsAdded] = useState(0)
    const [progress, setProgress] = useState(0)

    classes = useStyles();

    const user = useSelector(state => state.userState.user);

    const sectors = ["Contenido", "Fabricante de Equipo", "Fabricantes de Software", "Industria Telecomunicaciones", "Industria de TI", "Operadores", "OTT", "MVNO", "Redes Sociales", "Regulador"]

    useEffect(() => {
        //set sources
        if(localStorage.getItem("sources")){
            var temp = JSON.parse(localStorage.getItem("sources"))
            temp.map(item => {
                sources.push(item)
            })
        }


        //set countries
        let data = countries
        data.sort((a, b) => (a.name < b.name ? -1 : 1))
        setState({...state, countries: data});
        if(state.news.length !== 0){
            let seg = 0
            let sin = 0
            state.news.map(item => {
                if(item.newType === "Seguimiento a la Competencia"){
                    seg += 1
                } else{
                    sin += 1
                }
            })
            setState({...state, countries: data, seguimiento: seg, sintesis: sin})
        }
        
    },[])

    /* Handle input changes */
    const handleInputChange = (event) => {
        let value = event.target.value
        let name = event.target.name
        localStorage.setItem(`${name}`, value)
        setState({...state, [name]: value})
    }

    const handleSourceChange = (e) => {
        var value = e.target.value;
        var tempValue = value.toLowerCase()
        var temp = [];
        if(value.length < state.source.length){
            setState({...state, source: value})
        } else{
            sources.map(item => {
                var i = 0;
                var tempItem = item.toLowerCase()
                while(tempItem[i] == tempValue[i] && i < value.length) i++;
                
                if(i === value.length) temp.push(item)
        })

        setState({
                ...state,
                source: temp.length === 1 ? temp[0]  : e.target.value
            })
        }
    }

    const handleCompanyChange = (e) => {
        var value = e.target.value;
        var tempValue = value.toLowerCase()
        var temp = [];

        if(value.length < state.company.length){
            setState({...state, company: value})
        } else{
            companies.map(item => {
                var i = 0;
                var tempItem = item.toLowerCase()
                while(tempItem[i] == tempValue[i] && i < value.length) i++;

                if(i === value.length) temp.push(item)
            })

            setState({
                ...state,
                company: temp.length === 1 ? temp[0]  : e.target.value
            })
        }
    }
    
    const validPdfName = (name) => {
        let chars = ["á", "é", "í", "ó", "ú"]
        let result = false
        chars.map((item) => {
            if(name.includes(item)){
                result = true;
            }
        })
        if(result){
            alert("Nombre de pdf inválido")
        }
        return !result
    }

    const handlePdfChange = (e) => {
        if(e.target.files[0]){
            if(validPdfName(e.target.files[0].name)){
                setState({...state, pdf: e.target.files[0], pdfText: e.target.files[0].name});
            }
        }
    }

    const validateData = () => {
        if(state.country === ""){
            setState({...state, error: "selecciona un país chavin"})
            return false;
        } else if(state.title === ""){
            setState({...state, error: "escribe el título chavin"})
            return false;
        } else if(state.resumen === ""){
            setState({...state, error: "escribe el resumen chavin"})
            return false;
        } else if(state.source === ""){
            setState({...state, error: "escribe la fuente chavin"})
            return false;
        } else if(state.url === ""){
            setState({...state, error: "escribe la url chavin"})
            return false;
        } else if(state.newType === "Seguimiento a la Competencia" && state.company === ""){
            setState({...state, error: "escribe la empresa chavin"})
        } else if(!validPdfName(state.pdfText)){
            setState({...state, error: "cambia el pdf chavin"})
        }else if(state.pdf === null || state.pdf === undefined || state.pdf === ""){
            setState({...state, error: "agrega el pdf chavin"})
            return false;
        } else if(state.sector === ""){
            setState({...state, error: "selecciona el sector chavin"})
            return false;
        } else{
            setState({...state, error: ""})
            return true;
        }
    }

    const handleAddNew = (event) => {
        event.preventDefault()
        if(validateData()){
            var title = state.title.toLowerCase()
            title = title.replaceAll("&", "and")
            title = title.replaceAll(",", "")
            title = title.replaceAll("¿", "")
            title = title.replaceAll("?", "")
            title = title.replaceAll(";", "")
            title = title.replaceAll(".", "")
            var keywords = title.split(" ");
            const news = [...state.news]
            const newItem = {
                country: state.country,
                title: state.title,
                keywords: keywords,
                resumen: state.resumen,
                source: state.source,
                url: state.url,
                pdf: state.pdf,
                pdfText: state.pdfText,
                sector: state.sector,
                company: state.company,
                date: state.date,
                newType: state.newType,
                destacada: state.destacada
            }

            //check if source is new
            if(sources.indexOf(state.source) === -1){
                if(localStorage.getItem("sources")){
                    var temp = JSON.parse(localStorage.getItem("sources"))
                    temp.push(state.source)
                    console.log(temp)
                    localStorage.setItem("sources", JSON.stringify(temp))
                } else {
                    var temp = []
                    temp.push(state.source)
                    console.log(temp)
                    localStorage.setItem("sources", JSON.stringify(temp))
                }
            }



            news.push(newItem)
            setState({...state,
                    news: news,
                    country: "",
                    title: "",
                    resumen: "",
                    source: "",
                    url: "",
                    company: "",
                    pdf: "",
                    pdfText: "",
                    sector: "",
                    error: "",
                    sintesis: state.newType === "Seguimiento a la Competencia" ? state.sintesis : state.sintesis + 1,
                    seguimiento: state.newType === "Seguimiento a la Competencia" ? state.seguimiento + 1 : state.seguimiento
                })
            localStorage.setItem("addedNews",  JSON.stringify(news))
            localStorage.setItem("country", "")
            localStorage.setItem("title", "")
            localStorage.setItem("resumen", "")
            localStorage.setItem("source", "")
            localStorage.setItem("url", "")
            localStorage.setItem("pdf", null)
            localStorage.setItem("sector", "")
            localStorage.setItem("company", "")
        }

    }

    const handleSubmitTemp = (event) => {
        event.preventDefault()
        console.log(state.news)
        var count = 0
        const promises = []
        state.news.map((item) => {
            const uploadTask = firebase.storage.ref(`news/${item.newType}/${item.sector}/${item.country}/${item.pdf.name}`).put(item.pdf);
            promises.push(uploadTask)
            uploadTask.on(
                "state_changed",
                (snapshot) =>  {
                    // progress  function
                    const progress = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );
                    setProgress(progress);
                },
                (error) => {
                    // error function
                    alert(error.message);
                },
                async () => {
                    // complete function
                    await firebase.storage
                        .ref(`news/${item.newType}/${item.sector}/${item.country}`)
                        .child(item.pdf.name)
                        .getDownloadURL()
                        .then(url => {
                            firebase.db.collection("news").add({
                                country: item.country,
                                title: item.title,
                                keywords: item.keywords,
                                resumen: item.resumen,
                                source: item.source,
                                url: item.url,
                                pdf: url,
                                sector: item.sector,
                                company: item.company,
                                date: item.date,
                                newType: item.newType === "Seguimiento a la Competencia" ? 0 : 1,
                                destacada: item.destacada
                            }).then(() => {
                                count += 1
                                setNewsAdded(newsAdded => newsAdded + 1)
                                console.log("Document successfully written!");
                                console.log(count)
                                if(count === state.news.length){ 
                                    console.log("News added succesfully")
                                    setState({...state, success: "Se agregaron las noticias temporalmente!", seguimiento: 0, sintesis: 0, news: []})
                                    localStorage.setItem("addedNews",  JSON.stringify([]))
                                    localStorage.setItem("country", "")
                                    localStorage.setItem("title", "")
                                    localStorage.setItem("resumen", "")
                                    localStorage.setItem("source", "")
                                    localStorage.setItem("url", "")
                                    localStorage.setItem("pdf", null)
                                    localStorage.setItem("sector", "")
                                    localStorage.setItem("company", "")
                                    setProgress(0)
                                    setNewsAdded(0)
                                    firebase.db.collection("status").doc('QYeviLwq5wrtgDnZhjVU').set({
                                        status: false
                                    })
                                }
                            })
                        });
                    }
                )
        })
        Promise.all(promises)
            .then(() => console.log("All promises resolved"))
            .catch(err => console.log(err))
    }

    const handleSubmitTot = (event) => {
        event.preventDefault()
        var count = 0
        console.log(state.news)
        const promises = []
        state.news.map((item) => {
            const uploadTask = firebase.storage.ref(`news/${item.newType}/${item.sector}/${item.country}/${item.pdf.name}`).put(item.pdf);
            promises.push(uploadTask)
            uploadTask.on(
                "state_changed",
                (snapshot) =>  {
                    // progress  function
                    const progress = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );
                    setProgress(progress);
                },
                (error) => {
                    // error function
                    alert(error.message);
                },
                () => {
                    // complete function
                    firebase.storage
                        .ref(`news/${item.newType}/${item.sector}/${item.country}`)
                        .child(item.pdf.name)
                        .getDownloadURL()
                        .then(url => {
                            firebase.db.collection("news").add({
                                country: item.country,
                                title: item.title,
                                keywords: item.keywords,
                                resumen: item.resumen,
                                source: item.source,
                                url: item.url,
                                pdf: url,
                                company: item.company,
                                sector: item.sector,
                                date: item.date,
                                newType: item.newType === "Seguimiento a la Competencia" ? 0 : 1,
                                destacada: item.destacada
                            }).then(() => {
                                count += 1
                                setNewsAdded(newsAdded => newsAdded + 1)
                                console.log("Document successfully written!");
                                console.log(count)
                                if(count === state.news.length){ 
                                    console.log("News added succesfully")
                                    setState({...state, success: "Se agregaron las noticias totalmente!", seguimiento: 0, sintesis: 0, news: []})
                                    localStorage.setItem("addedNews",  JSON.stringify([]))
                                    localStorage.setItem("country", "")
                                    localStorage.setItem("title", "")
                                    localStorage.setItem("resumen", "")
                                    localStorage.setItem("source", "")
                                    localStorage.setItem("url", "")
                                    localStorage.setItem("pdf", null)
                                    localStorage.setItem("sector", "")
                                    localStorage.setItem("company", "")
                                    setProgress(0)
                                    setNewsAdded(0)
                                    firebase.db.collection("status").doc('QYeviLwq5wrtgDnZhjVU').set({
                                        status: true
                                    })
                                }
                            })
                        });
                    }
                )
        })
        Promise.all(promises)
            .then(() => console.log("All promises resolved"))
            .catch(err => console.log(err))
    }

    //editState
    const [editState, setEditState] = useState({
        country:  "",
        newType: "",
        destacada: "",
        source: "",
        url: "",
        sector: "",
        pdf: null,
        pdfText: "",
        resumen: "",
        company: "",
        title:  "",
        date: "",
        error: "",
        success: ""
    })

    const handleEditPdfChange = (e) => {
        if(e.target.files[0]){
            setEditState({...editState, pdf: e.target.files[0], pdfText: e.target.files[0].name});
        }
    }

    const handleEditSourceChange = (e) => {
        var value = e.target.value;
        var tempValue = value.toLowerCase()
        var temp = [];
        if(value.length < editState.source.length){
            setEditState({...editState, source: value})
        } else{
            sources.map(item => {
                var i = 0;
                var tempItem = item.toLowerCase()
                while(tempItem[i] == tempValue[i] && i < value.length) i++;
                
                if(i === value.length) temp.push(item)
        })

        setEditState({
                ...editState,
                source: temp.length === 1 ? temp[0]  : e.target.value
            })
        }
    }

    const handleEditCompanyChange = (e) => {
        var value = e.target.value;
        var tempValue = value.toLowerCase()
        var temp = [];
        if(value.length < editState.company.length){
            setEditState({...editState, company: value})
        } else{
            companies.map(item => {
                var i = 0;
                var tempItem = item.toLowerCase()
                while(tempItem[i] == tempValue[i] && i < value.length) i++;

                if(i === value.length) temp.push(item)
            })

            setEditState({
                ...editState,
                company: temp.length === 1 ? temp[0]  : e.target.value
            })
        } 
    }

    const getCountryImage = (country) => {
        var img = ""
        countries.map(item => {
            if(item.name === country){
                img = item.imgUrl
            }
        })
        return img
    }


    const handlEditChange = (index, item) => {
        if(!state.news[index].status){
            setEditState({...editState,
                country:  item.country,
                newType: item.newType,
                destacada: item.destacada,
                source: item.source,
                url: item.url,
                sector: item.sector,
                pdf: item.pdf,
                company: item.company,
                pdfText: item.pdfText,
                resumen: item.resumen,
                title:  item.title,
                date: item.date,
                showPdf: false,
            })
        }
        const data = [...state.news];
        data.forEach((item, i) => { i === index ? item.status = !state.news[index].status : item.status = false });
        setState({...state, news: data});
    }

    const handleEditInputChange = (event) => {
        let value = event.target.value
        let name = event.target.name
        localStorage.setItem(`${name}`, value)
        setEditState({...editState, [name]: value})
    }

    const validateEditData = () => {
        if(editState.country === ""){
            setEditState({...editState, error: "selecciona un país chavin"})
            return false;
        } else if(editState.title === ""){
            setEditState({...editState, error: "escribe el título chavin"})
            return false;
        } else if(editState.resumen === ""){
            setEditState({...editState, error: "escribe el resumen chavin"})
            return false;
        } else if(editState.source === ""){
            setEditState({...editState, error: "escribe la fuente chavin"})
            return false;
        } else if(editState.url === ""){
            setEditState({...editState, error: "escribe la url chavin"})
            return false;
        } else if(editState.newType === "Seguimiento a la Competencia" && editState.company === ""){
            setEditState({...editState, error: "escribe la empresa chavin"})
            return false;
        } else if(editState.pdf === null){
            setEditState({...editState, error: "agrega el pdf chavin"})
            return false;
        } else if(editState.pdf !== null){
            if(!validPdfName(editState.pdfText)){
                setEditState({...editState, error: "cambia el pdf chavin"})
                return false;
            }
        } else if(editState.sector === ""){
            setEditState({...editState, error: "selecciona el sector chavin"})
            return false;
        }
        setEditState({...editState, error: ""})
        return true;
    }

    const handleDelete = (index) => {
        const news = [...state.news]
        news.splice(index, 1)
        setState({...state, 
            news: news,
            seguimiento: state.news[index].newType === "Seguimiento a la Competencia" ? state.seguimiento - 1 : state.seguimiento,
            sintesis: state.news[index].newType === "Seguimiento a la Competencia" ? state.sintesis : state.sintesis - 1,
        })
        localStorage.setItem("addedNews",  JSON.stringify(news))
    }

    const handleUpdatedNew = (index) => {
        //actualizar datos de noticia
        if(validateEditData()){
            var title = editState.title.toLowerCase()
            title = title.replaceAll("&", "and")
            title = title.replaceAll(",", "")
            title = title.replaceAll("¿", "")
            title = title.replaceAll("?", "")
            title = title.replaceAll(";", "")
            title = title.replaceAll(".", "")
            var keywords = title.split(" ");
            var newType = state.news[index].newType
            let news = [...state.news]
            news[index].title = editState.title
            news[index].keywords = keywords
            news[index].country = editState.country
            news[index].resumen = editState.resumen
            news[index].url = editState.url
            news[index].sector = editState.sector
            news[index].pdf = editState.pdf
            news[index].pdfText = editState.pdfText
            news[index].company = editState.company
            news[index].date = editState.date
            news[index].destacada = editState.destacada
            news[index].newType = editState.newType
            news[index].source = editState.source
            news[index].status = false
            console.log(editState)
            if(newType !== editState.newType){
                setState({...state, 
                    news: news,
                    sintesis: editState.newType === "Seguimiento a la Competencia" ? state.sintesis - 1 : state.sintesis + 1,
                    seguimiento: editState.newType === "Seguimiento a la Competencia" ? state.seguimiento + 1 : state.seguimiento - 1
                })
            } else{
                setState({...state, news: news})
            }
            localStorage.setItem("addedNews",  JSON.stringify(news))
        } else {
            console.log(editState)
        }
        
    }

    const handleShowPDF = () => {
        setEditState({...editState, showPdf: !editState.showPdf})
    }
    

    return (
        <div className={classes.backgroundContainer}>
            <div className={classes.overlay}>
                {!user && (
                    <Redirect to="/"/>
                )}
                <Header/>
                <h1 className={classes.title}>Sube una noticia chavin</h1>
                <Row>
                    <Col lg={6}>
                        <div className={classes.formContainer}>
                            {/* Pais */}
                            <FormControl variant="outlined" className={classes.formControl}>
                                <FormLabel component="legend" style={{color: "white", fontSize: "20px", fontWeight: 'bolder'}}>Pais Noticia</FormLabel>
                                <Select
                                    native
                                    value={state.country}
                                    onChange={handleInputChange}
                                    name="country"
                                    label="Pais"
                                    input={<TransparentInput style={{marginBottom: 30}}/>}
                                >
                                    <option aria-label="None" value=""/>
                                    {state.countries && state.countries.map((item, i) => (
                                        <option key={i} value={item.name}>{item.name}</option>
                                    ))}
                                </Select>
                            </FormControl>

                            {/* Titulo */}
                            <input placeholder={"Título noticia"} name="title" onChange={handleInputChange} value={state.title}  className={classes.input}/>

                            {/* resumen */}
                            <textarea rows="5" placeholder={"Resumen noticia"} name="resumen" onChange={handleInputChange} value={state.resumen} className={classes.input}/>

                            {/* Fuente */}
                            <input placeholder={"Fuente noticia"} name="source" onChange={handleSourceChange}  value={state.source} className={classes.input}/>

                            {/* Url */}
                            <input placeholder={"Url noticia"} name="url" onChange={handleInputChange}  value={state.url} className={classes.input}/>

                            {state.newType === "Seguimiento a la Competencia" && (
                                <input placeholder={"Empresa noticia"} name="company" onChange={handleCompanyChange} value={state.company} className={classes.input}/>
                            )}

                            {/* Archivo PDF */}
                            <div className={classes.input}>
                                <input type="file" name="pdf" onChange={handlePdfChange}/>
                            </div>
                            

                            {/* Sector */}
                            <FormControl variant="outlined" className={classes.formControl}>
                                <FormLabel component="legend" style={{color: "white", fontSize: "20px", fontWeight: 'bolder'}}>Sector Noticia</FormLabel>
                                {/* <InputLabel id="demo-simple-select-outlined-label" className={classes.selectLabel}>Sector Noticia</InputLabel> */}
                                <Select
                                    native
                                    value={state.sector}
                                    onChange={handleInputChange}
                                    label="Sector Noticia"
                                    name="sector"
                                    input={<TransparentInput style={{marginBottom: 30}}/>}
                                >
                                    <option aria-label="None" value=""/>
                                    {sectors.map((item, i) => (
                                        <option key={i} value={item}>{item}</option>
                                    ))}
                                </Select>
                            </FormControl>

                            
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className={classes.formContainer}>
                            {/* Fecha */}
                            <input value={state.date} type="date" name="date" onChange={handleInputChange}  className={classes.input}/>

                            {/* Newsletter */}
                            <FormLabel component="legend" style={{color: "white", fontSize: "20px", fontWeight: 'bolder'}}>Newsletter Noticia</FormLabel>
                            <RadioGroup aria-label="newType" name="newType" value={state.newType} onChange={handleInputChange}>
                                <FormControlLabel style={{color: "white", fontSize: "20px", fontWeight: 'bolder'}} value="Seguimiento a la Competencia" control={<WhiteRadio  />} label="Seguimiento a la Competencia" />
                                <FormControlLabel style={{color: "white", fontSize: "20px", fontWeight: 'bolder'}} value="Síntesis Regional" control={<WhiteRadio />} label="Síntesis Regional" />
                            </RadioGroup>

                            {/* Destacada */}
                            <FormLabel component="legend" style={{color: "white", fontSize: "20px", fontWeight: 'bolder'}}>Noticia destacada</FormLabel>
                            <RadioGroup aria-label="destacada" name="destacada" value={state.destacada} onChange={handleInputChange}>
                                <FormControlLabel style={{color: "white", fontSize: "20px", fontWeight: 'bolder'}} value="Si" control={<WhiteRadio  />} label="Sí" />
                                <FormControlLabel style={{color: "white", fontSize: "20px", fontWeight: 'bolder'}} value="No" control={<WhiteRadio />} label="No" />
                            </RadioGroup>

                            {/* progress */}
                            <progress style={{margin: 'auto', width: '100%', marginBottom: '20px'}} value={progress} max="100"/>

                            {/* Submit */}
                            <WhiteButton onClick={handleAddNew}>Agregar noticia</WhiteButton>

                            {/* Error */}
                            {state.error !== "" && (
                                <div className={classes.errorContainer}>
                                    <h2 className={classes.errorText}>{state.error}</h2>
                                </div>
                            )}

                            <div className={classes.newsLengthContainer}>
                                <h4 className={classes.newsLength}>Seguimiento: {state.seguimiento}</h4>
                                <h4 className={classes.newsLength}>Síntesis  : {state.sintesis}</h4>
                            </div>

                            <div className={classes.btnsContainer}>
                                <BlackButton className={classes.btn} onClick={handleSubmitTemp}>Subir temporal</BlackButton>
                                <BlackButton className={classes.btn} onClick={handleSubmitTot}>Subir total</BlackButton>
                            </div>

                            <div className={classes.newsLengthContainer}>
                                <h4 className={classes.newsLength}>Noticias agregadas: {newsAdded}/{state.news.length}</h4>
                            </div>

                            {/* Success */}
                            {state.success !== "" && (
                                <div className={classes.errorContainer}>
                                    <h2 className={classes.errorText}>{state.success}</h2>
                                </div>
                            )}
                        </div>
                    </Col>
                </Row>

                {/* News container */}
                <div className={classes.newsContainer}>
                    <h3 className={classes.subtitle}>Edita las noticias chavin</h3>
                    <div className={classes.newsInnerContainer}>
                        {state.news.length !== 0 ? (
                            <>
                                {state.news.map((item, i) => (
                                    <div key={i} className={classes.newContainer}>
                                        <div className={classes.newItemContainer}>
                                
                                            {/* country */}
                                            <div className={classes.countryContainer}>
                                                <div className={classes.flag} style={{backgroundImage: `url(${getCountryImage(item.country)})`, backgroundSize: item.country === "Dominicana" ? "cover" : "auto"}}></div>
                                                <p className={classes.flagName}>{item.country}</p>
                                            </div>

                                            {/* title */}
                                            <div className={classes.itemContainer}>
                                                <p className={classes.itemText}>{`${item.title.substring(0,15)}...`}</p>
                                            </div>

                                            {/* resumen */}
                                            <div className={classes.itemContainer}>
                                                <p className={classes.itemText}>{`${item.resumen.substring(0,15)}...`}</p>
                                            </div>

                                            {/* fuente */}
                                            <div className={classes.itemContainer}>
                                                <p className={classes.itemText}>{`${item.source.substring(0,15)}`}</p>
                                            </div>

                                            {/* url */}
                                            <div className={classes.itemContainer}>
                                                <p className={classes.itemText}>{`${item.url.substring(0,15)}`}</p>
                                            </div>

                                            {/* pdf */}
                                            <div className={classes.itemContainer}>
                                                <p className={classes.itemText}>{`${item.pdfText.substring(0,15)}`}</p>
                                            </div>

                                            {/* sector */}
                                            <div className={classes.itemContainer}>
                                                <p className={classes.itemText}>{`${item.sector.substring(0,15)}`}</p>
                                            </div>

                                            {/* fecha */}
                                            <div className={classes.itemContainer}>
                                                <p className={classes.itemText}>{`${item.date}`}</p>
                                            </div>

                                            {/* tipo */}
                                            <div className={classes.itemContainer2}>
                                                <p className={classes.itemText}>{`${item.newType === "Seguimiento a la Competencia" ? "Seg" : "Sin"}`}</p>
                                            </div>

                                            {/* destacada */}
                                            <div className={classes.itemContainer2}>
                                                <p className={classes.itemText}>{`${item.destacada}`}</p>
                                            </div>

                                            {/* Edit */}
                                            {!item.status && (
                                                <IconButton onClick={() => {handlEditChange(i, item)}}>
                                                    <CreateRoundedIcon className={classes.icon}/>
                                                </IconButton>
                                            )}

                                            {/* Delete */}
                                            {!item.status && (
                                                <IconButton onClick={() => {handleDelete(i)}}>
                                                    <DeleteRoundedIcon className={classes.icon}/>
                                                </IconButton>
                                            )}

                                            {/* Close */}

                                            {item.status && (
                                                <IconButton  onClick={() => {handlEditChange(i, item)}}>
                                                    <CancelRoundedIcon className={classes.icon}/>
                                                </IconButton>
                                            )}
                                            
                                        </div>

                                        {/* Edit form */}
                                        <Collapse in={item.status}>
                                            <Row>
                                                <Col lg={6}>
                                                    <div className={classes.formContainer2}>
                                                        {/* Pais */}
                                                        <FormControl variant="outlined" className={classes.formControl}>
                                                            <FormLabel component="legend" style={{color: "white", fontSize: "20px", fontWeight: 'bolder'}}>Pais Noticia</FormLabel>
                                                            <Select
                                                                native
                                                                value={editState.country}
                                                                onChange={handleEditInputChange}
                                                                label="Pais"
                                                                name="country"
                                                                input={<TransparentInput style={{marginBottom: 30}}/>}
                                                            >
                                                                <option aria-label="None" value=""/>
                                                                {state.countries && state.countries.map((item, i) => (
                                                                    <option key={i} value={item.name}>{item.name}</option>
                                                                ))}
                                                            </Select>
                                                        </FormControl>

                                                        {/* Titulo */}
                                                        <input placeholder={"Título noticia"} name="title" onChange={handleEditInputChange} value={editState.title}  className={classes.input}/>

                                                        {/* resumen */}
                                                        <textarea rows="5" placeholder={"Resumen noticia"} name="resumen" onChange={handleEditInputChange} value={editState.resumen} className={classes.input}/>

                                                        {/* Fuente */}
                                                        <input placeholder={"Fuente noticia"} name="source" onChange={handleEditSourceChange}  value={editState.source} className={classes.input}/>

                                                        {/* Url */}
                                                        <input placeholder={"Url noticia"} name="url" onChange={handleEditInputChange}  value={editState.url} className={classes.input}/>

                                                        {/* Empresa */}
                                                        {editState.newType === "Seguimiento a la Competencia" && (
                                                            <input placeholder={"Empresa noticia"} name="company" onChange={handleEditCompanyChange} value={editState.company} className={classes.input}/>
                                                        )}

                                                        {/* Archivo PDF */}
                                                        <div className={classes.pdfContainer}>
                                                            <div className={classes.pdfInnerContainer}>
                                                                {editState.showPdf ? (
                                                                    <IconButton onClick={handleShowPDF}>
                                                                        <CancelRoundedIcon className={classes.icon}/>
                                                                    </IconButton>
                                                                ) : (
                                                                    <IconButton onClick={handleShowPDF}>
                                                                        <CreateRoundedIcon className={classes.icon}/>
                                                                    </IconButton>
                                                                )}
                                                                
                                                                <p className={classes.pdfText}>{editState.pdfText}</p>
                                                            </div>
                                                            <input style={{display: editState.showPdf ? "block" : "none"}} type="file" name="pdf" onChange={handleEditPdfChange}/>
                                                        </div>
                                                        
                                                        

                                                        {/* Sector */}
                                                        <FormControl variant="outlined" className={classes.formControl}>
                                                            <FormLabel component="legend" style={{color: "white", fontSize: "20px", fontWeight: 'bolder'}}>Sector Noticia</FormLabel>
                                                            <Select
                                                                native
                                                                value={editState.sector}
                                                                onChange={handleEditInputChange}
                                                                label="Sector Noticia"
                                                                name="sector"
                                                                input={<TransparentInput style={{marginBottom: 30}}/>}
                                                            >
                                                                <option aria-label="None" value=""/>
                                                                {sectors.map((item, i) => (
                                                                    <option key={i} value={item}>{item}</option>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </div>
                                                </Col>
                                                <Col lg={6}>
                                                    <div className={classes.formContainer2}>
                                                        {/* Fecha */}
                                                        <input value={editState.date} type="date" name="date" onChange={handleEditInputChange}  className={classes.input}/>

                                                        {/* Newsletter */}
                                                        <FormLabel component="legend" style={{color: "white", fontSize: "20px", fontWeight: 'bolder'}}>Newsletter Noticia</FormLabel>
                                                        <RadioGroup aria-label="gender" name="newType" value={editState.newType} onChange={handleEditInputChange}>
                                                            <FormControlLabel style={{color: "white", fontSize: "20px", fontWeight: 'bolder'}} value="Seguimiento a la Competencia" control={<WhiteRadio  />} label="Seguimiento a la Competencia" />
                                                            <FormControlLabel style={{color: "white", fontSize: "20px", fontWeight: 'bolder'}} value="Síntesis Regional" control={<WhiteRadio />} label="Síntesis Regional" />
                                                        </RadioGroup>

                                                        {/* Destacada */}
                                                        <FormLabel component="legend" style={{color: "white", fontSize: "20px", fontWeight: 'bolder'}}>Noticia destacada</FormLabel>
                                                        <RadioGroup aria-label="gender" name="destacada" value={editState.destacada} onChange={handleEditInputChange}>
                                                            <FormControlLabel style={{color: "white", fontSize: "20px", fontWeight: 'bolder'}} value="Si" control={<WhiteRadio  />} label="Sí" />
                                                            <FormControlLabel style={{color: "white", fontSize: "20px", fontWeight: 'bolder'}} value="No" control={<WhiteRadio />} label="No" />
                                                        </RadioGroup>

                                                        {/* Submit */}
                                                        <WhiteButton onClick={() => {handleUpdatedNew(i)}}>Actualizar noticia</WhiteButton>

                                                        {/* Error */}
                                                        {editState.error !== "" && (
                                                            <div className={classes.errorContainer}>
                                                                <h2 className={classes.errorText}>{editState.error}</h2>
                                                            </div>
                                                        )}

                                                        {/* Success */}
                                                        {editState.success !== "" && (
                                                            <div className={classes.errorContainer}>
                                                                <h2 className={classes.errorText}>{editState.success}</h2>
                                                            </div>
                                                        )}
                                                    </div>
                                                </Col>
                                            </Row>
                                            
                                        </Collapse>
                                    </div>
                                ))}
                            </>
                        ) : (
                            <div className={classes.errorContainer}>
                                <p className={classes.errorText}>No hay noticias</p>
                            </div>
                        )}
                    </div>

                </div>
            </div>
        </div>
    )
}
